#blogHome {
  background-color: #f4eddd !important;
  background: url("../img/backgroundHome.png") top center;
  background-size: cover;
  color: #273c89;
  position: relative;
  padding: 50px 0px 50px 30px;
}

.blogHeader {
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

/* .blogHeader-sm {
  display: none;
} */

.blogHeader a {
  color: #273c89;
}

.blogHeader h2 {
  font-family: "Aileron", Sans-serif, serif;
  font-size: 36px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 43px;
  color: #273c89;
}

.blogHeader img {
  position: absolute;
  right: -31px;
  top: 96px;
}

.blogContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /*padding: 100px 10px 10px;*/
}

.blogCard {
  display: flex;
  align-content: center;
  width: 100%;
  margin-bottom: 25px;
}

.blogCardContent {
  /*padding: 42px;*/
  padding: 0px 0px 0px 50px;
  /*margin-right: -45px;*/
}

.blogCardContent h2 {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 36px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 42.2px;
  position: relative;
  margin-bottom: 20px;
  width: fit-content;
}

.blogCardContent h2::before {
  position: absolute;
  content: "";
  height: 32px;
  width: 49px;
  background: url("../img/blog/BlogHeadingImage.png") no-repeat;
  background-size: 100%;
  right: -65px;
  top: 0px;
}

.blogCardParagraph {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}

.blogCardContent a {
  font-family: "Aileron", Sans-serif, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  fill: #273c89;
  color: #273c89;
  text-decoration: none;
  display: inline-block;
}

.blogCardContent a:hover{
  font-weight: bold;
}

.blogCardReadMore {
  /*margin-top: 20px;*/
}

.blogCardReadMore svg {
  font-size: 20px;
  padding-left: 5px;
}

@media (min-width: 1199px) {
  .blogHeader {
    display: flex;
  }

  .blogHeader-sm {
    display: none;
  }
}

@media (max-width: 1198px) {
  #blogHome {
    padding: 70px 40px 70px 40px;
  }

  .blogHeader {
    display: none;
    bottom: 28px !important;
  }

  .blogHeader h2 {
    font-size: 27px;
  }

  .blogHeader-sm {
    display: flex;
    margin-bottom: 0;
  }

  .blogCard {
    flex-direction: column;
    padding-bottom: 30px;
  }

  .blogCard img {
    width: 100%;
    text-align: center;
    height: 100%;
  }

  .blogCardContent {
    padding: 20px 0 0 0;
  }

  .blogCardContent h2 {
    font-size: 24px;
    line-height: 28.8px;
  }

  .blogCardParagraph {
    font-size: 16px;
    line-height: 19px;
  }

}

@media (max-width: 1200px){
  .homeBlogLink{
    display: flex;
    width: 100%;
    justify-content: end;
  }
  .columnWrapRel.homeBlogLink{
    padding: 0;
  }
}


@media (max-width: 780px) {
  .blogCard-3 {
    display: none;
  }
}

@media (max-width: 757px) {
  .blogHeader h2 {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .blogHeader {
    right: 60px;
  }

  .blogHeader h2 {
    font-size: 18px;
  }
}


