.teamPage{
  min-height: 100vh;
  width: 100%;
  align-items: center;
  font-family: Aileron, sans-serif;
  background: #273c89;
  color: #fff;
  padding: 80px 60px 90px;
}

.teamContainer{
  justify-content: center;
}

.teamLinksContainer {
  justify-content: space-between;
  align-items: center;
}

.linksContainer a {
  text-decoration: none;
  color: #fff;
}

.teamLink {
  margin-top: -20px;
}

.teamLink a:hover {
  color: #fff;
  text-decoration: none;
}

.teamLink a:hover > img {
  transform: scale(1.05);
}

.meetTeamText{
  font-size: 48px;
  font-weight: 900;
  padding-left: 1rem;
}

.teamValuesText{
  font-size: 30px;
  font-weight: 900;
  display: inline-block;
}

.teamParagraph {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 1.6em;
}

.teamParagraph p {
  padding: 2rem 0;
}

/* Team Members */
.teamMembers{
  justify-content: space-between;
}

.member{
  width: 30%;
}

.memberImageContainer{
  width: 100%;
}

.memberImageContainer img {
  height: 325px;
  object-fit: cover;
  object-position: 50% 30%;
  width: 100%;
}

.member h3{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  margin-top: 10px;
}

.member p {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.4;
}

.bePartOfTheTeam{
  padding-top: 100px;
  justify-content: end;
  align-items: center;
}

.bePartText{
  font-size: 24px;
  margin-right: 2rem;
}

.applyBtn{
  width: 30%;
  font-size: 20px;
  font-weight: 900;
  color: #273c89;
  padding: 18px;
  border: none;
  cursor: pointer;
}

.loginSection.applyNowForm{
  /* display: none; */
}

.loginContainer.applyNowContainer{
  width: 80%;
}

.loginContainer.applyNowContainer .loginInnerContainer{
  height: 95%;
}

.applyNowFormContainer{
  height: 100%;
  width: 100%;
}

.loginForm.applyForm{
  width: 100%;
}

.loginForm.applyForm form{
  display: flex;
  flex-direction: column;
}

.loginForm.applyForm form input,
.loginForm.applyForm form textarea{
  padding: 1rem;
  font-size: 20px;
  margin-bottom: 2rem;
  color: #273c89;
  border-radius: 3px;
  border: 1px solid #273c89;
  outline: none;
}
 
.loginForm.applyForm form input[type="file"]{
  border: none;
} 

.loginForm.applyForm form input::placeholder,
.loginForm.applyForm form textarea::placeholder{
  color: #273c89;
  opacity: 0.7;
}

.applyNowBtn{
  background: #273c89;
  color: #fff;
  padding: 1.5rem;
  border: 1px solid #273c89;
  border-radius: 3px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}

.file_input{
  margin-bottom: 20px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  font-size: 18px !important;
  letter-spacing: 1.2px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #273c89;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}
/* Media Queries */
@media (min-width: 768px) and (max-width: 1200px){
  .meetTeamText{
    font-size: 40px;
  }
  .teamLink{
    margin-top: 0;
    align-self: center;
  }
}

@media screen and (max-width: 768px){
  .teamPage{
  padding: 80px 30px 90px;
  }
  .teamTitle .meetTeamText{font-size: 28px !important;}
  .teamLinksContainer.flexContainer {
    flex-direction: column;
  }
  .teamLinksContainer .teamTitle {
    flex-direction: row;
    align-self: flex-start;
    margin-bottom: 20px;
  }
  .teamLinksContainer .teamLink{
    align-self: flex-end;
  }
  .teamLink a h3{
    margin-right: -30px;
  }
  .teamTitle img{
    width: 50px;
  }
  .teamTitle.flexContainer{
    flex-direction: row;
  }
  .teamTitle .meetTeamText{font-size: 30px;}
  .teamLink .teamValuesText{font-size: 20px;}
  .member h3{
    font-size: 18px;
    text-align: center;
  }

  .teamParagraph {
    text-align: center;
  }

  .member p{
    font-size: 18px;
    text-align: center;
  }

  .teamMembers.flexContainer{
    flex-direction: column;
  }

  .teamMembers .member {
    width: 100%;
  }

  .bePartOfTheTeam.flexContainer {
    flex-direction: column;
    width: 100%;
  }

  .applyBtn{
    width: 100%;
  }
}

@media screen and (max-width: 500px){
  .memberImageContainer{
    text-align: center;
  }
  .memberImageContainer img{
    width: 235px;
    height: 298px;
  }
  .file-input__label{
    font-size: 14px !important;
  }
  .applyNowBtn{
    padding: 1rem;
    font-size: 16px;
  }
}