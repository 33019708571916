/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Aileron";
} */

.fw-bold {
  font-weight: bold;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-10 {
  width: 10%;
}

.w-40 {
  width: 40%;
}

.flex {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
  color: #fff;
}

ul {
  list-style-type: none;
  text-align: center;
}

.btn {
  background: #273c89;
  color: #fff;
  font-weight: bold;
  border: none;
  display: block;
  margin-bottom: 0.75rem;
  padding: 0.75rem 0;
  width: 180px;
  text-align: center;
  cursor: pointer;
}

.main-content {
  background: #273c89;
  display: flex;
  /* border: 3px solid green; */
}

.productDetail{
  display: flex;
  width: 100%;
  padding: 80px 40px;
}

.productDetailPage{
  display: flex;
  justify-content: center;
  width: 100%;
}

.productDetailPage .merchandise-content{
  padding: 0;
}

/* Aside */
aside {
  /* border: 2px solid red; */
  width: 350px !important;
  height: 100vh;
  background-color: #273c89;
  color: #fff;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  position: sticky;
  top: 0;
}

aside li {
  margin: 1.1em 0;
}

aside a:hover {
  border-bottom: 1px solid #fff;
}

.logo {
  margin: 4rem 0 1rem;
}

.menu-btn {
  background: #273c89;
  border: 1px solid #fff;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 0.75rem 3.25rem;
  cursor: pointer;
  transition: background 0.25s ease;
}

.menu-btn:hover {
  background: #fff;
  color: #273c89;
}

#locations-link{
  z-index: 100;
}

.locations-link {
  display: block;
  color: #273c89;
  background: #f4eddd;
  font-size: 22px;
  padding: 0.6rem 0;
  width: 100%;
  font-weight: 900;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0 -15px;
}

.locations-link:hover{
  border: 0;
  text-decoration: none;
}

.locations-blue{
  color: #fff;
  background: #273c89;
  border-top: 2px solid #fff;
  z-index: 100;
  transition: color 0.15s ease-in;
  text-decoration: none;
}

.locations-blue:hover,
.locations-blue:focus{
  cursor: pointer;
  color: var(--hover-color);
  border-top: 2px solid #fff;
  text-decoration: none;
  outline: none;
}

#backIcon{
  cursor: pointer;
  font-size: 18px;
  transition: color 0.15s ease-in;
}

#backIcon:hover{
  color: var(--hover-color);
}

/* Merchandise Content */
.merchandise-content * {
  font-size: 14px;
}

.merchandise-content {
  background: #fff;
  /* border: 2px solid red; */
  padding: 6em 3em 4em 0;
  display: flex;
  flex-direction: column;
  color: #273c89;
}

/* Categories */
.categories {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 1rem;
}

.categories > div {
  /* border: 2px solid red; */
  margin-right: 3rem;
  display: flex;
  align-items: center;
}

.category {
  width: 50%;
}

.sortProduct {
  width: 45%;
}

.categories-logo img {
  cursor: pointer;
}

.categories-logo img:first-child {
  margin-right: 10px;
}

.searchBar {
  position: relative;
  border-bottom: 1px solid #273c89;
}

.searchBar img {
  position: absolute;
  right: 1px;
  width: 25px;
}

.select {
  width: 65%;
  border: none;
  background: inherit;
  margin-left: 10px;
  color: #273c89;
  font-weight: bold;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #273c89;
  outline: none;
  cursor: pointer;
}

/* Product Info */
.product-info {
  /* border: 2px solid black; */
  padding: 1rem 0;
  margin: 1rem 0;
}

.product-info > div {
  /* border: 1px solid red; */
  margin-right: 1rem;
}

.back-arrow {
  /* display: none; */
  width: 10%;
  /* margin-left: 10px; */
}

.back-arrow a {
  display: inline-block;
  transition: transform 0.15s ease-in;
}

.back-arrow a:hover{
  transform: scale(1.05);
}

.add-to-cart-btn:hover,
.add-to-favourites-btn:hover,
.your-review .btn:hover{
  color: var(--hover-color);
}


.product-image {
  display: flex;
  width: 35%;
  /* height: 60vh; */
}

.product-image img {
  width: 100%;
  aspect-ratio: 1.66;
  align-self: center;
}

.product-description {
  width: 40%;
  /* padding-right: 10%; */
  padding: 2rem 6rem 2rem 3rem;
}

.product-description h2 {
  font-size: 36px;
}

.write-review .label.reviewName{
  color: #273c89;
}

.other-products .product{
  cursor: pointer;
}

.product-description h2,
.product-description span {
  font-weight: 800;
  margin-bottom: 0.6rem;
}

.leadParagraph{
  font-size: 16px;
}

.product-description span {
  display: block;
  font-size: 24px;
}

.product-description p {
  margin: 1rem 0;
}

.product-rating {
  margin: 0.75rem 0;
}

.btn i {
  margin-right: 3px;
}

/* Product Reviews */
.product-reviews {
  /* border: 2px solid orangered; */
}

.reviews-text {
  align-items: center;
  margin-bottom: 1rem;
}

.reviews-text h1 {
  font-size: 36px;
  font-weight: 700;
}

.reviews-text hr {
  display: block;
  width: 100%;
  margin: 0.4rem 1rem 0;
  border: 1px solid #273c89;
}

.review {
  display: flex;
}

.user-review {
  width: 80%;
  padding-top: 0.3rem;
}

.user-like {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-like i {
  cursor: pointer;
  transition: transform 0.15s ease-in;
}

.user-like i:hover{
  transform: scale(1.05);
}

.italic {
  font-style: italic;
}

.user-review p {
  margin-bottom: 1rem;
}

.review > div {
  /* border: 1px solid red; */
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.your-review {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
}

.review-border{
  width: 60%;
}

.your-review > div {
  margin-right: 2rem;
}

.review {
  border: 0;
  color: #273c89;
  outline: none;
  font-size: 14px;
  padding-right: 7rem;
}

.write-review input[type="file"] {
  display: none;
}

.write-review label {
  margin-top: 5px;
  cursor: pointer;
  margin-left: 5rem;
  display: none;
}

.review-border {
  padding-bottom: 5px;
  border-bottom: 2px solid #888;
  align-items: center;
}

.file {
  padding-top: 5px;
}

.label {
  margin: 5px 15px 0 5px;
}

.review::placeholder {
  color: #273c89;
}

.your-review hr {
  width: 50%;
  border: 1px solid #888;
  position: absolute;
  bottom: 8px;
}

/* Similar Products */
.similar-products {
  /* border: 2px solid aqua; */
  margin-top: 1rem;
}

.similar-products h1 {
  margin: 4rem 0;
  font-size: 30px;
  font-weight: 700;
}

.similar-products a {
  color: #273c89;
}

.similar-products a:hover {
  text-decoration: underline;
}

.product {
  margin-right: 2rem;
}

.prod-info {
  /* border: 2px solid greenyellow; */
  display: flex;
  justify-content: space-around;
  border: 1px solid #f4eddd;
}

.searchInput{
  border: none;
  outline: none;
}

.prod-save-buy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prod-save-buy i {
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.prod-info > div {
  /* border: 2px solid aqua; */
  margin: 1rem 1rem 0 0;
}

/* Footer */
footer {
  background: #273c89;
  color: #fff;
}

.footer-top {
  margin-bottom: 1rem;
  justify-content: center;
}

.footer-top > div {
  display: flex;
  align-items: flex-start;
  margin: 1rem;
}

.reward-text {
  width: 350px;
  padding: 1rem 0;
}

.download {
  width: 145px;
  text-align: start;
  padding: 1rem 0;
}

.download img {
  margin-right: 10px;
}

.footer-bottom {
  justify-content: space-between;
  padding: 1rem 2rem;
}

.address-location {
  display: flex;
  margin-right: 1rem;
}

.location-icon {
  margin-right: 10px;
}

.follow-us {
  width: 100px;
}

.icons i {
  cursor: pointer;
}

.icons i:first-child {
  margin-right: 1.5rem;
}

.links {
  width: 150px;
  display: flex;
  flex-direction: column;
}

.links a {
  margin-bottom: 15px;
}

@media (max-width: 965px){

}