.reviewCard {
  height: 360px !important;
}
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50% !important;
  transform: translateX(-50%) !important;
  white-space: nowrap;
}
.swiper-pagination {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  z-index: 10;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #e6e6e6;
  border-radius: 50%;
  margin: 0 5px;
  opacity: 0.8;
  cursor: pointer;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: blue;
  width: 8px;
  height: 8px;
}
.swiper-navBtn {
  color: #273c89 !important;
}
.loginErrorMsgFontSize {
  font-size: 12px;
  font-weight: 700;
}
.exclamationCircleOnError {
  color: #c20808;
  font-size: 13px;
  margin-right: 5px;
}

.layoutLocationsContainer {
  padding: 1rem 0 2rem 2rem !important;
  height: 234px !important;
}
.star {
  /* color: white; */
  color: #273c89;
}

.star-filled {
  color: white;

}
.starsRating {
  font-size: 25px;
}

.lostPasswordForm p {
  font-size: 21px !important;

  color: black;
}
.lostPasswordForm .registerFormLabelInput button {
  font-size: 18px !important;
}
.lostPasswordForm label {
  font-size: 18px;
  color: #273c89;
  opacity: 0.8;
  font-weight: 700;
}
.emailSentPlsCheck {
  font-size: 24px;
  text-align: center;
}
.reviewCard {
  height: 360px !important;
  display: flex;
}

.itemMenu:hover{
  cursor: pointer;
}
.menuarrow{
  font-size: 17px;
    margin-left: 4px;
    margin-bottom: 4px;
}
.locationsBox span{
  width: 100%;
}

i:hover{
  cursor: pointer;
  transform: scale(1.5);
}
button:disabled{
  cursor: not-allowed;;
  background-color:#989793 !important;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: #7785b7;
}
.backdrop-payment {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: #7785b7;
  opacity: 0.9;
}
.loader{
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0909da;
  width: 60px !important;
  height: 60px !important;
  position:relative;
  top:20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  background-color:transparent !important;
}
.loading-spinner {
  position: fixed;
  top: 20vh;
  left: 45%;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
} 
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (min-width: 768px) {
  .loading-spinner  {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.confirmation_modal {
  position: fixed;
  top: 20vh;
  left: 25%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.confirmation_modal_header {
  background: #c8cce0;
  padding: 1rem;
  text-align: center;
}
.confirmation_modal_actions {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin-left:33rem;

}
.confirmation_modal_btn {
  padding: 1rem;
 
}
/*for review section(login)*/
.review_margin{
  margin-bottom: 2.2rem;

}
/*for cart wish icons*/
.cart_wish{
  margin-top: 2.2rem;
  display: flex;
  justify-content: end;

}
#searchBtn:hover,#wishBtn:hover,#cartBtn:hover,#wishButton:hover,#cartButton:hover,#sendReviewUser:hover{
  text-decoration-color: #333;
  background-color: #273c89 
}
.row_items{
  display: inline-flex;
}


#item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#item header {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

#item img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  margin-right: 10px;
}

#item h3 {
  margin: 0;
  color: #274d52;
}

#item #price {
  margin-left: auto;
  margin-top: -35px;
}

#item #itemprice {
  font-weight: bold;
}

#item #details {
  display: flex;
  align-items: center;
  margin-left: 20px;
  flex-direction: column;
}

#item #quantity {
  margin-right: 10px;
}

#item #actions button {
  margin: 0 5px;
  padding: 3px 19px;
  border: none;
  background-color: #ccc;
  color: #fff;
  cursor: pointer;
}

#total {
  text-align: right;
  margin-top: 40px;
  font-size: 24px;
  color: #465068;
}
.totalPriceContainer{
  display: flex;
  width: 100%;
  justify-content: end;

}
#totalValue{
 font-weight: bold;
}
#quantityActions {
  display: flex;
  align-items: center;
}

.quantityButton {
  margin: 0 5px;
  padding: 3px 19px;
  border: none;
  background-color: #d5d3e2;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantityButton:hover {
  background-color: #ccc;
  /* color: #fff; */
 color: #273c89 
}

.LoginDetailsCart {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  /* margin-left: 29px; */
}

.orderHistoryTwoButton {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

.startAReturn {
  background-color: #4caf50;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 6px!important;
  /* margin-left: 26px; */
}

.startAReturn:hover {
  background-color: #45a049;
}

.checkoutButton {
  margin-right: 10px;
  border-radius: 6px!important;
}

@media (min-width: 768px) {
  .orderHistoryTwoButton {
    flex-direction: row;
  }

  .startAReturn {
    margin-bottom: 0;
  }

  .closeButton {
    margin-left: 10px;
  }
}

.addToWishListHeart{
  color: #273c89 !important;
  display: flex;
  padding-top: 23px;
  font-size: 20px;
  font-weight: 900;
}


.headingOfProduct{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 19px;
}
.deleteButtonOfeachAddedCartItem{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #9a9999;
  border-radius: 4px;
  background: #a1a1ac;
  color: #060666;
  font-size: 16px;
  font-weight: 600;
  margin-top: -3px;
}

.detailsFirstDiv{
  display: flex;
  padding: 17px;
  padding-top: 44px;
  margin-top: -35px;
}
.totalPriceOfEachItem{
  font-weight: 700;
}

.deleteButtonDivOfEachAddedCartItem{
  padding-left: 34px;
}
.deleteButtonOfeachAddedCartItem:hover{
  background-color: red;
  color: #bec2d3;
}


.loginError {
 
  align-self: start;

}
.wishlistHeart{
  cursor: pointer;
}
.emptyWishList{
  display: flex;
  text-align: center;
  width:100%;
  /* border: 1px solid red; */
  font-size: 20px;
  font-weight: 700;
  color: #273c89;
  /* justify-content: center; */
  /* align-items: center; */
}
.profilWishlistAndCartLogo{
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  font-size: 15px;
  color: #273c89;
}
.profileCartIcon{
  margin-left: 23px;
}
.wishListContainerPopup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.wishListPopup {
  position: fixed;
  color: silver;
  background: #2d4485;
  /* width: 164px; */
  /* height: 71px; */
  display: flex;
  top: 20px;
  justify-content: center;
  right: 20px;
  /* background-color: #fff; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  padding: 15px;
  border-radius: 5px;
  animation: slide-in 0.3s ease-out;
  z-index: 9999;
}

.wishListPopupContent {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide-in {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
.wishListEachImage img{
  width: 100%;
  
  object-fit: cover;
  min-height: 77px;
  max-height: 78px;
  padding: 10px;

}

@media (min-width: 1200px) {
  .profilePaymentCheckoutPage{
  
      margin-left: -377px !important;
      /* padding: 0; */
      margin-top: -124px;
  }
}
@media (max-width: 1200px) {
  .profilePaymentCheckoutPage{
  
    
      margin-top: -50px;
  }
}
.orderItemContainer{
  /* width: 100%; */
  margin-top: 40px;
  background: #f3f6ff;
  border-radius: 5px;
}
.orderItemDetails{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: white;
  padding: 1rem;
  padding-bottom: 1rem;
}
.orderItemSubContainer{
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}
.orderItemSubContainer-1{
  padding: 0.5rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  background-color: rgba(0, 0, 0, 0.03);
}
.orderItemSubContainer-100{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
}
.shippAddress{
  margin: 1rem;
  margin-top: 0;
  padding: 1rem;
  border: 1px solid  rgba(0, 0, 0, 0.175);
  border-radius: 5px;
  color: #273c89
}
.shipAdressTitle{
  margin-left: 1rem;
  color: #273c89;
}
.mainHeader-Id{
  font-size: 1.25rem;
  color: #273c89;
  font-weight: 400;
}
.secondHeader-Id{
  font-size: .875em;
  color: #6c757d;
  font-weight: 400;
}
.orderItemImages{
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 10px;
}
.orderItemName{
  font-size: 18px;
  margin-bottom: 10px;
}

.address-container {
  width: 80%;
  margin: 0 auto;
  margin-top: 40px;
  padding: 20px;
  /* background-color: #f7f7f7; */
  background: #f3f6ff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
.address-container_Heading{
  margin: 0 auto;
  width: 76%;
  font-size: 22px;
  margin-top: 50px;
  margin-bottom: -23px;
  padding: 8px;

  box-shadow: 0px 0px 6px 5px #7a7c98;
  color: #474365;
  text-align: center;
  display: flex;
    font-size: 26px;
    /* margin-bottom: 34px; */
    /* width: 100%; */
    text-align: center;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    /* padding: 5px; */
    color: #451167;
    box-shadow: 0px 0px 1px 2px #1a1b51;
}
.address-details {
  /* background-color: #e1e0e7; */
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9; */
  transition: background-color 0.3s ease;
  opacity: 0.8;
  color: #273c89;
}


.address-detailsLabel {
  font-weight: bold;
}
.address-details:hover {
  cursor: pointer;
  /* background-color: #e9e9e9; */
}

.selected-address {
  /* background-color: blue; */
  color: #fff;
}
/* Add more CSS styles as needed */
.fetchingDataEmptyInprofile{
  margin-top: 10px;
  color: #273c89;
  font-weight: 700;
  font-size: 20px;
}
.userAddressFormMerchandiseForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userAddressFormMerchandiseColumns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.userAddressFormMerchandiseRow {
  display: flex;
  flex-direction: column;
  justify-content:center;
  width: 100%;
}
.userAddressFormMerchandiseRow input{
  width: 100%;
  padding: 4px;
  padding-left: 15px;
  margin-bottom: 10px;
  font-weight: 500;
}
.chckoutAddNewAdressButton{
  background-color: #273c89;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-bottom: 24px;
}
.userAddressFormMerchandiseButtons{
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  width: 100%;
  margin-top: 12px;
  
}
.userAddressFormMerchandiseBtn_save{
  background-color: #273c89;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  width: 100%;
margin-right: 4px;

}
.userAddressFormMerchandiseBtn_cancel{
  background-color: #273c89;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-left: 4px;
}
.SummaryOfOrderHeading{
  display: flex;
  font-size: 26px;
  margin-bottom: 34px;
  width: 100%;
  text-align: center;
  border: 1px solid;
  justify-content: center;
  align-items: center;
  padding: 5px;
  color: #451167;
  box-shadow: 0px 0px 1px 2px #1a1b51;
}
.userAdressButtonUpdateAndDelete{
  display: flex;
  width: 100%;
}
.userAdressButtonUpdateAndDelete button{
  display: inline-block;
  color: #526cd0;
  border: none;
  cursor: pointer;
  /* margin: 3px; */
  width: 50%;
  text-transform: uppercase;
  /* padding: 14px 14px; */
  padding: 10px;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  border-radius: 6px;
  background: #f6f6f6;
  /* box-shadow: -1px -1px 0px 0px #8c92b6; */
}
.allAdressDetailsOfUser{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 19px;
  box-shadow: 1px -1px 3px 1px #8e8a8a;
  border-radius: 5px;
  margin: 1rem;
}
.eachAdressExplainedDetails{
  display: flex;
  flex-direction: column;
  /* padding: 30px; */
  padding-left: 20px;
  padding-top: 10px;
  color: #032572;
}
.adressCodeandAll{
  margin-top: 8px;
  font-weight: 400;
}
.adressCodeandAll h6{
  color: #0b235a;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.9;
  /* border: 0.4px solid #d0d0da; */
}
.allAdressDetailsOfUser-input{
    width: 20px;
    height: 16px;
    cursor: pointer;
}
.allAdressDetailsOfUser-edit{
    width: 22px;
    height: 22px;
    cursor: pointer;
}
.allAdressDetailsOfUser-delete{
    width: 28px;
    height: 28px;
    cursor: pointer;
}
.allAdressDetailsOfUser-right-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 10px,
}
.navBarOfProfilePage {
  z-index: 10000;

}
.profilePaymentCheckoutPage{
  width: 100%;
}
.paymentcard {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* align-items: center; */
  width: 100vw;
  padding-top: 4rem;
}
.paymentTypeContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.creditCardContainer{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

}
.paypalContainer{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.paymentButtontoAdd{
  background-color: #273c89;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.paymentTypeContainer img{
  cursor: pointer;

}
.paypalContainer input{
  position: absolute;
  left: 0;
}
.creditCardContainer input{
  position: absolute;
  left: 0;
}
.paymentChooseMethodHeading{
  color: #765454;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  margin-top: 27px;
  margin-bottom: 43px;
  cursor: pointer;
  font-weight: 700;
}
.shipingAdressMethodHeading{
  color: #765454;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
  margin-top: 41px;
  margin-bottom: 11px;
  cursor: pointer;
  font-weight: 700;
}
.paypalFormContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.paypalFormContainer div{

  width: 100%;
}
.paypalInputEmail input{
  padding: 7px;
  width: 100%
}
.saveAndCancelOfPaypalForm{
  display: flex;
  width: 100%;
  margin-top: 13px;
}
.saveAndCancelOfPaypalForm button{
  background-color: #273c89;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  width: 100%;

}
.gapBetweensaveAndCancel{
  width: 19px!important;
}
.gridContainerPaypalList{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}
.eachpayaplEmail{
  padding: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  flex-wrap: wrap;
  position: relative;
}
.eachpayaplEmail input{
  position: absolute;
}
.paypalEmail{
  margin-top: 20px;
  margin-bottom: 9px;
  border: 0.4px solid #d0d0da;
}
.paypalEmailDeletButton{
  color: #273c89;
  padding: 5px;
  border-radius: 5px;
  border: none;
  width: 100%;
  font-weight: 600;
}
.paddeachemail{
  padding: 10px;
}
.creditCardFormContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.creditCardFormContainer input{
  padding: 7px;
  width: 100%;
}
.crediCarrdcancelDelet{
  display: flex;
  width: 100%;
  margin-top: 7px;
}
.crediCarrdcancelDelet button{
  background-color: #273c89;
  color: white;
  padding: 5px;
  border-radius: 5px;
  border: none;
  width: 100%;

}
.gridContainerCredCardlList{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}
.eachcreditCardData{
  padding: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  flex-wrap: wrap;
  position: relative;

}
.eachcreditCardData input{
  position: absolute;
  cursor: pointer;
}
.creditCardDataofAll{
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  color: #5b528b;
  opacity: 0.8;
}
.creditCardDataofAll button{
  border: none;
  border-radius: 5px;
  color: #273c89;
  font-weight: 600;
  width: 100%;
  padding: 5px;
}
.creditContainer{
  margin-top: 20px;
}
.orderHistoryTwoButton{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start!important;
  flex-direction: row !important;
  width: 100% !important;
  padding: 0px !important;
}
.divorderHistoryTwoButton{
  width: 100% !important
}
.colorDarkForCardData{
  color: #120f7a;
  position: absolute;
  opacity: 1;
  right: 27px;
}
.creditCardDataofAll h6{
  border: 0.4px solid #d0d0da;
}
.buttonbackOfPayment{
  margin-top: 4px;
}
/*pagination*/
.tableFooter_pagination {
  background-color: #f1f1f1;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_pagination {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton_pagination {
  color: white;
  background: #185adb;
}

.inactiveButton_pagination {
  color: #2c3e50;
  background: #f9f9f9;
}
.inactiveButton_pagination hover{
  color: white;
  background: #022a75;
}
/*end pagination*/