#pageContainer {
  padding-left: 300px;
  position: relative;
  overflow: hidden;
}

.pageContainerWrap {
  /*max-width: 90%;*/
  margin-right: auto;
  margin-left: auto;
}

.pageContainerWrapCenter {
  padding: 0;
  display: block;
}

.sectionHomeBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 1140px;
}

.row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

.centered {
  align-content: center;
  align-items: center;
}
.errorForm {
  color: red;
  padding: 1rem;
  font-size: 1rem; 
  background-color: #f9f9f9; 
  border: 1px solid #e74c3c; 
  border-radius: 4px; 
  margin: 0.5rem 0; 
}


@media (max-width: 1160px) {
  .row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.row > * {
  margin: 0;
  padding: 0;
}

.columnWrap {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: flex;
}

.columnWrapRel {
  width: 100%;
  position: relative;
  padding: 0px 20px 20px 0px;
}

.wrap {
  padding: 0px 0px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  align-content: center;
  align-items: center;
}

.col-100 {
  width: 100%;
}

.col-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/*@media (max-width: 940px) {*/
  /*.columnWrap {*/
    /*padding: 30px 0px 0px 0px;*/
  /*}*/
/*}*/

.mainButton {
  border: none;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: #273c89;
  padding: 13px 10px 14px;
  font-size: 18px;
  line-height: 19px;
  font-family: "Aileron", Sans-serif, serif;
  width: 100%;
  height: 46px;
  color: white;
  margin-top: 15px;
}

.mainButton:active,
.mainButton:focus,
.mainButton:hover {
  color: white;
  background-color: #000000;
}


.marginTop-20 {
  margin-top: 20px;
}

.marginBottom-10 {
  margin-bottom: 10px;
}


@media (min-width: 1199px) {

}

@media (max-width: 1198px) {
  .columnWrap {
    padding: 30px 0px 0px 0px;
  }
}