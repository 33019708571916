.ProfilePageSection {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 100px 70px 75px;
  background: #f3f6ff;
  font-family: "Aileron", Sans-serif;
}
.NameAndPhotoContainer {
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-bottom: 50px;
}
.RightNameAndPhotoContainer {
  color: #273c89;
  font-size: 36px;
  font-weight: 900;
}
.LeftNameAndPhotoContainer {
  position: relative;
}
.imagePhotoIcon {
  border-radius: 50%;
  object-fit: cover;
  width: 156px;
  height: 156px;
}
.cameraButtonOfPhoto {
  width: 33px;
  height: 33px;
  background: #ecc36c;
  border-radius: 50%;
  text-align: center;
  line-height: 29px;
  position: absolute;
  bottom: 0px;
  right: 6px;
  color: #273c89;
  cursor: pointer;
}
.navBarOfProfilePage {
  display: flex;
  /* justify-content: space-between; */
}
.navBarOfProfilePage div a {
  color: #273c89;
  padding: 0;
  background: transparent;
  font-weight: 400;
  font-size: 24px;
  cursor: pointer;
}

.activeLink {
  font-weight: 900 !important;
}

.navBarOfProfilePage div a.activeLink {
  font-weight: 900;
}

.myAccountBtn {
  font-weight: 700;
  padding: 10px 25px;
  color: #fff;
  background: #273c89;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid #273c89;
}

.myAccountBtn.updateBtn {
  padding: 16px 20px;
  max-width: 378px;
  width: 100%;
  line-height: 21.6px;
  font-weight: 900;
  margin: 62px 3px 22px;
  transition: color var(--hover-transition);
}

.myAccountBtn.updateBtn:hover{
  color: var(--hover-color);
}

.orderHistoryTwoButton .myAccountBtn {
  font-size: 16px;
}

.ProfilePageSection a {
  text-decoration: none;
}

/* My Accoumt */
.MyAccountContainer {
  display: flex;
  width: 100%;
  padding-top: 45px;
}

.LoginDetails {
  width: 40%;
}
.PersonalDetails {
  width: 60%;
  border-left: 1px solid #273c89;
}

.LoginDetailsHeader,
.PersonalDetailsHeader {
  border-bottom: 1px solid #273c89;
  font-weight: 700;
  padding: 15px 0;
  font-size: 20px;
  color: #273c89;
}

.PersonalDetailsHeader {
  padding-left: 30px;
}

.inputLoginDetailsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 30px;
}

.inputLoginDetailsContainer input,
.PersonalDetailsForm div input,
.personalDetailsBox select {
  border: 0.5px solid #273c89;
  background-color: transparent;
  height: 55px;
  color: #273c89;
  font-size: 18px;
  padding: 0.75em;
  transition: all 0.2s linear;
  cursor: text;
}

.PersonalDetailsForm div input {
  opacity: 1;
}

.inputLoginDetailsContainer label {
  font-size: 18px;
  line-height: 21.6px;
  color: #273c89;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: 5px;
  font-style: inherit;
  position: relative;
}

.PersonalDetailsForm {
  padding-top: 15px;
  padding-left: 30px;
}

.PersonalDetailsForm form div label {
  font-size: 18px;
  line-height: 21.6px;
  color: #273c89;
  opacity: 0.5;
  margin-bottom: 5px;
}

.personalDetailsBox {
  display: flex;
  flex-direction: column;
}

.PersonalDetailsForm div {
  padding: 3px;
  margin: 0 0 6px;
  max-width: 378px;
}

/* Order History */
.OrderStatusAndOrderNumberDetailConatiner {
  display: flex;
  padding-top: 15px;
  justify-content: space-between;
  padding-right: 80px;
}
.orderHistoryStatusText {
  font-weight: 700;
  font-size: 18px;
  color: #273c89;
}
.orderNumbersText {
  font-weight: 700;
  font-size: 18px;
  color: #273c89;
}
.orderNumberValue {
  font-size: 17px;
  color: #273c89;
  padding-top: 15px;
}
.SavedForDetailsStatus {
  color: #273c89;
  margin-top: 41px;
  padding-left: 30px;
  padding-top: 15px;
  font-size: 18px;
}
.dateDelivered {
  font-size: 17px;
  color: #273c89;
  margin-top: -4px;
}
.deliveredText {
  font-size: 17px;
  color: #273c89;
  padding-top: 15px;
}
.SavedForDetailsHeader {
  padding-left: 30px !important;
  padding-top: 15px !important;
  border-bottom: 1px solid #273c89;
  font-weight: 700;
  padding: 15px 0;
  font-size: 20px;
  color: #273c89;
}
.orderHistoryTwoButton {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-right: 30px;
}
.startAReturn {
  font-weight: 700;
  padding-top: 11px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
  height: 45px;
  color: #ffffff;
  background-color: #273c89 !important;
  border-radius: 0px !important;
  border: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-color: var(--ast-global-color-0);
  /* width: 40%; */
}
.viewOrder {
  font-weight: 700;
  padding-top: 11px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-left: 25px;
  height: 45px;
  color: #ffffff;
  background-color: #273c89 !important;
  border-radius: 0px !important;
  border: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-color: var(--ast-global-color-0);
}

.orderHistory {
  height: 400px;
}
.orderHistoryRightContainerHeight {
  height: 360px;
}

/* .orderHistoryBigWidth .LoginDetails,
.orderHistoryBigWidth .PersonalDetails {
  width: 46%;
} */

/* My Wishlist */
.WishlistContainer {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 40px;
}
.wishListItemsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.wishlistItem75 {
  width: 75%;
  display: flex;
}
.wishlistItem25 {
  /* width: 100%; */
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wishListItemsContainerItemDiv {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 33.3%;
  color: #273c89;
}
.borderRightOfMywishList {
  border-right: 1px solid #273c89;
}
.wishList {
  padding-bottom: 30px;
}
.wishlistPriceAndHeart div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  height: 100%;
}
.BorderBottomOfEachWishlistItemsContainer {
  border-bottom: 1px solid #273c89;
}
.AddtoCartWishListItemButton div {
  background-color: #273c89;
  position: relative;
  width: 162px;
  padding: 15px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  height: 40px;
  padding-top: 9px;
  cursor: pointer;
}
.cartWishlist {
  width: 17px;
  position: relative;
  margin-right: 7px;
  margin-top: -4px;
}

/* Payment Details */
.paymentcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
  padding-top: 4rem;
}

.addCard,
.payWithPaypal {
  border: 1px solid #273c89;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem;
}

.add-card-btn.myAccountBtn {
  margin-top: 2rem;
}
/* Change Password  */
.changePassword {
  padding-top: 50px;
  padding-bottom: 28px;
}
.legendPara {
  font-size: 20px;
  color: #273c89;
  font-weight: 400;
  margin: 10px 0 40px;
  width: 100%;
  border: 0;
  outline: 0;
}
.ChangePasswordFormContainer form div label {
  font-size: 18px;
  line-height: 21.6px;
  color: #273c89;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: 5px;
}
.ChangePasswordFormContainer form div {
  display: flex;
  flex-direction: column;
  width: 380px;
}
.ChangePasswordFormContainer form div input {
  width: 100%;
  outline: 0;
  border: 0.5px solid #273c89;
  background-color: transparent;
  height: 55px;
  color: #273c89;
  font-size: 18px;
  font-weight: 400;
  padding: 0.75em;
  transition: all 0.2s linear;
}
.OldPassword,
.NewPassword,
.VerifyNewPassword {
  position: relative;
}
.eyeIconOfPassword {
  position: absolute;
  top: 47px;
  right: 16px;
  color: #273c89;
  font-size: 18px;
}
.UpdateButtonOfPasswordDetails {
  background-color: #273c89;
  padding: 17px 20px;
  font-size: 17px;
  line-height: 21.6px;
  font-weight: 900;
  text-align: center;
  color: white;
  margin-top: 2em;
}
.UpdateButtonOfPasswordDetails:hover {
  cursor: pointer;
  background-color: rgb(11, 14, 61);
}

/* Media Queries */
@media (max-width: 1370px){
  .orderHistoryTwoButton .myAccountBtn{
    width: 48.5%;
  }
}

@media (max-width: 1260px) {
  .PersonalDetailsForm div {
    max-width: 100%;
  }
  .LoginDetails {
    width: 46%;
  }
  .OrderHistoryDetails {
    width: 46%;
  }
  .SavedForLater {
    width: 46%;
  }
  .PersonalDetails {
    width: 46%;
  }
  .MyAccountContainer {
    justify-content: flex-start;
  }
}

@media (max-width: 1230px) {
  .navBarOfProfilePage div a {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .navBarOfProfilePage div a {
    font-size: 18px;
  }
  .navBarOfProfilePage div {
    padding: 10px;
  }
  .MyAccountContainer {
    padding-left: 12px;
  }
  .ProfilePageSection {
    padding: 80px 20px 68px;
  }
}

@media (max-width: 870px) {
  .orderHistoryTwoButton .myAccountBtn {
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .SmallWidthProfile {
    display: none;
  }
}

@media (max-width: 768px) {
  input {
    outline: none;
  }
  .ProfilePageSection {
    padding: 80px 30px 70px;
  }

  .MyAccountContainer.myaccountContainerBigWidth,
  .bigWidthProfile {
    display: none;
  }
  .loginError {
    width: 100%;
  }
  /* Name And Photo Image Container  */
  .NameAndPhotoContainer {
    flex-direction: column;
    justify-content: baseline;
  }
  .imagePhotoIcon {
    width: 100px;
    height: 100px;
  }
  .RightNameAndPhotoContainer {
    font-size: 30px;
    line-height: normal;
    margin-top: 25px;
  }

  /* My Account */
  .MyAccountContainer {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-top: -47px;
  }
  .LoginDetails {
    width: 100%;
  }
  .LoginDetailsHeader {
    border-bottom: none;
  }
  .inputLoginDetailsContainer {
    padding-right: 0px;
  }

  .PersonalDetails {
    width: 100%;
    border-left: none !important;
  }
  .PersonalDetailsHeader {
    border-bottom: none;
    padding-left: 0px;
  }
  .PersonalDetailsForm {
    padding-left: 0px;
  }

  .SmallWidthProfile div h3 {
    color: #273c89;
    font-size: 20px;
    font-weight: 400;
    border-bottom: 1px solid #273c89;
    padding-bottom: 15px;
    position: relative;
  }
  .myAccountBtn.updateBtnSmall {
    width: 100%;
    margin: 40px 0 35px;
  }
  .SWPmyAccount a.activeLink h3{
    font-weight: 900;
  }
  .SWPmyAccount h3::after,
  .SWPOtherLinks h3::after {
    content: "";
    border-bottom: 3px solid #273c89;
    border-right: 3px solid #273c89;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
    position: absolute;
    right: 0;
    transition: all 0.5s ease;
    transform: rotate(-135deg);
    top: 15px;
  }
  .SWPOtherLinks h3::after {
    transform: rotate(45deg);
    top: 10px;
  }
  .PersonalDetailsForm div {
    padding: 0px;
    margin: 0 0 13px;
  }
  .SWPmyAccount {
    cursor: pointer;
    margin-bottom: 35px;
  }
  .SWPOtherLinks {
    cursor: pointer;
    margin-bottom: 30px;
  }
  .activeIdOfProfileNavbar {
    margin-bottom: 35px;
  }

  /* order History  */
  .orderHistoryBigWidth {
    display: none;
  }

  .orderHistory {
    height: auto;
  }

  .OrderStatusAndOrderNumberDetailConatiner {
    padding-right: 0px;
  }
  .orderHistoryTwoButton {
    flex-direction: column;
  }
  .orderHistoryTwoButton .myAccountBtn.startReturnBtn {
    margin-bottom: 20px;
  }
  .SavedForDetailsHeader {
    padding-left: 0px !important;
    padding: 7px 0;
    font-size: 18px;
  }
  .viewOrder {
    margin-top: 20px;
  }
  .orderHistoryTwoButton {
    padding-right: 0;
  }
  .orderHitoryTextHeader {
    display: none;
  }
  .SavedForDetailsStatus {
    padding-left: 0px;
    padding-top: 0px;
    margin-top: 25px;
  }
  .orderHistoryRightContainerHeight {
    height: auto;
    padding-bottom: 52px;
    padding-top: 25px;
  }
  /* My Wishlist */
  .wishListBigwidth {
    display: none;
  }
  .wishListItemsContainer {
    flex-direction: column;
  }
  .wishlistItem25-100 {
    width: 100%;
  }
  .wishlistItem75-100 {
    width: 100%;
  }
  .WishlistItemName {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
  }
  .AddtoCartWishListItemButton div {
    width: 86vw;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .AddtoCartWishListItemButton.wishListItemsContainerItemDiv {
    width: 100%;
  }

  /* Payment Details */
  .paymentCardBigWidth{
    display: none;
  }
  .paymentcard{
    flex-direction: column;
  }
  .addCard,
  .payWithPaypal{
    width: 100%;
    margin-bottom: 4rem;
  }
  .paymentDetailContainer{
    padding: 1em 2em 1em 3.5em;
    margin: 0 0 2em;
    position: relative;
    background: #f7f6f7;
    color: #515151;
    border-top: 3px solid #0170b9;
  }
  .NoSavedMethodFound{
    position: relative;
    border: 0;
    font-size: 18px;
  }
  .logoPaymentDetail::before {
  font-family: WooCommerce;
  content: "\e028";
  display: inline-block;
  position: absolute;
  top: -11px;
  left: -38px;
  font-size: 30px;
  }
  /* Change Password */
  .BigWidthChangePassword {
    display: none;
  }
  .legendPara {
    font-size: 18px;
    line-height: 21.6px;
  }
  .changePassword {
    padding-top: 0px;
  }
}

@media (max-width: 440px){
  .ChangePasswordFormContainer form div{
    width: auto;
  }
}

@media (max-width: 360px){
  .add-card-btn.myAccountBtn{
    font-size: 16px;
    text-align: center;
  }
}