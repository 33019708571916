:root {
  --hover-color: #acaccb;
  --hover-transition: 0.15s ease-in;
}

#home {
  width: 100%;
  height: 100vh;
  background: url("../img/backgroundBlog2.png") top center;
  background-color: #f3f6ff !important;
  background-size: cover;
  padding-top: 140px;
  /*padding: 140px 90px 40px 92px;*/
}

.homeSection {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  position: absolute;
  /*left: 370px;*/
  /*top: 150px;*/
  height: 498px;
  /*overflow: hidden;*/
}

#home .userName{
  display: flex;
  max-width: 70%;
  margin: 0 auto;
  color: #273c89;
  font-weight: 800;
  font-size: 48px;
  padding-bottom: 1rem;
}

.homeSectionRight {
  background-color: #ecc36c;
  width: 40%;
  display: flex;
  /*justify-content: center;*/
  /*flex-direction: column;*/
  z-index: 10;
  /*padding: 20px 30px 20px 40px;*/
  height: 100%;
}
.homeSectionRight h2 {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-weight: 900;
  /*margin-bottom: 15px;*/
  /*margin-top: -50px;*/
  font-size: 34px;
  line-height: 1.3;
}

.homeSectionRightContent {
  padding: 0px 50px 0px 50px;
}

.homeSectionRightContent .title {
  margin-bottom: 20px;
}

.homeSectionRightContent .content {
  margin-bottom: 20px;
}

.homeSectionRightContent .slogan {
  margin-bottom: 20px;
}

.homeSectionRight p {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  /*margin-top: 25px;*/
  line-height: 24px;
}

.homeSectionLeft {
  position: relative;
  width: 60%;
  overflow: hidden;
}

.iframeDiv {
  padding-top: 87%;
}

.homeSectionRightContent a {
  text-decoration: none;
  box-shadow: 0px 0px 20px 1px #fff;
}

.waitlistButton {
  background-color: #273c89;
  /*padding: 10px;*/
  padding: 16px 0px 17px 0px;
  color: white;
  text-align: center;
  font-size: 18px;
  /*margin-top: 42px;*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-bottom: -64px;*/
  font-family: "Aileron", Sans-serif;
  font-weight: 900;
  transition: color 0.15s ease-in;
  cursor: pointer;
}

.waitlistButton:hover {
  color: var(--hover-color);
}

.responsive-iframe {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  border: none;
  overflow: hidden;
}

/* Welcome Popup  */
#WelcomePopupSection {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  /* top: 0; */
  bottom: 0;
  height: 100vh;
  z-index: 10000000;
  transition-timing-function: ease-in-out;
  opacity: 0.98;
}
.WelcomePopupImageContainer img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.sloganPar {
  height: 50px;
  margin-top: 35px;
  margin-bottom: 7px;
}

.sloganPar span {
  margin-top: -4px;
  font-style: inherit;
  font-weight: inherit;
  color: #273c89;
  font-family: "Aileron", Sans-serif;
  line-height: 24px;
  font-size: 18px;
  font-weight: 400;
}

.loginToReviewDiv input {
  color: #273c89;
  font-size: 18px;
  padding-left: 10px;
  border: 0;
  outline: 0;
  font-style: italic;
  width: 70%;
}

.loginToReviewDiv input::placeholder{
  font-style: italic;
  color: #273c89;
  font-weight: 400;
}

.verifyEmailPopup{
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
}

.verifySuccess{
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verifyEmailSection{
  height: 100vh;
  background: #acaccb;
}

/* setNewPassword Page */
.resetPasswordFormContainer .formDataContainer{
  min-width: 400px;
  padding: 10px 0;
  font-size: 16px;
  color: #273c89;
}

.resetPasswordFormContainer .formDataContainer input {
  padding: 8px;
}

::-webkit-scrollbar {
  height: 100%;
  width: 8px;
  background: #ecc36c;
  padding-right: 5px;
  margin-right: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #273c89;
  margin-right: 5px;
}

::-webkit-scrollbar-corner {
  background: #000;
}

.locations p {
  font-size: 14px;
}

@media (min-width: 1201px){
  .verifyEmailPopup{
    padding-left: 300px;
  }
}

@media (max-width: 1199px) {
  #home {
    height: auto !important;;
  }

  .homeSection {
    flex-direction: column;
    left: 0px;
    right: 0;
    top: 150px;
    height: 100%;
    overflow: hidden;
  }

  .homeSectionRight {
    height: auto;
    /*width: 80vw;*/
    width: 100%;
    margin-bottom: 8px !important;
  }

  .homeSectionLeft {
    /*height: 100%;*/
    width: 100%;
    /*width: 80vw;*/
  }

  .waitlistButton {
    /*margin-bottom: 60px;*/
  }

  .homeSectionRight h2 {
    margin-top: 0px;
  }

  .responsive-iframe {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 768px){
  .resetPasswordFormContainer .formDataContainer{
    min-width: inherit;
  }
}

@media (max-width: 600px) {
  #home {
    height: 107vh;
  }

  .waitlistButton {
    margin-bottom: 0px;
  }

  .homeSection {
    height: 100%;
  }

  .homeSectionRightContent {
    padding: 0px 35px 30px 35px;
  }

  .homeSectionRight {
    padding: 0px;
  }

  .homeSectionRight h2 {
    /*margin-top: 30px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .homeSectionRight p {
    display: flex;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .homeSectionRightPar {
    font-style: inherit;
    font-weight: inherit;
  }

  .sloganPar {
    margin-top: -4px !important;
    font-style: inherit;
    font-weight: inherit;
  }

  .responsive-iframe {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media (max-width: 500px) {
  .waitlistButton {
    width: 105%;
    margin-left: -9px;
  }
}

@media (max-width: 470px) {
  .waitlistButton {
    width: 106%;
    margin-left: -9px;
  }
}

@media (max-width: 370px) {
  .waitlistButton {
    width: 107%;
    margin-left: -9px;
  }
}

@media (max-width: 1800px) {
  #home {

  }
  .sectionHomeBody {
    max-width: 1025px;
    padding: 0px 40px 40px 40px;
  }
}


@media (max-width: 1750px) {

  .homeSectionRightContent {
    padding: 0px 40px 0px 40px;
    margin-top: 10px;
  }

  .homeSectionRight p {
    font-size: 18px;
  }

  .homeSectionRightContent .title {
    margin-bottom: 0px;
  }

  .homeSectionRightContent .content {
    margin-bottom: 0px;
  }

}

@media (max-width: 1600px) {
  #home {

  }
  .sectionHomeBody {
    max-width: 932px;
  }

  .homeSectionRight h2 {
    font-size: 30px;
  }

  .homeSectionRightContent {
    padding: 0px 35px 0px 35px;
  }
  .waitlistButton{
    padding: 9px 0px 9px 0px;
  }
}

@media (max-width: 1400px) {
  #home {
    /*padding-top: 100px !important;*/
  }
  .sectionHomeBody {
    max-width: 893px;
  }

  .homeSectionRightContent {
    padding: 0px 35px 30px 35px;
  }

  .homeSectionRight h2 {
    font-size: 28px;
  }

  .waitlistButton {
    padding: 5px 0px 10px 0px;
  }

  .homeSectionRight p {
    font-size: 18px;
    margin-top: 5px;
  }

}

@media (max-width: 1024px) {
  #home {
    padding-top: 80px !important;
  }
  .sectionHomeBody {
    max-width: 1024px;
  }
}


@media (max-width: 780px) {

  .sectionHomeBody {
    max-width: 877px;
  }
}

@media (max-width: 500px) {
  .sectionHomeBody {
    max-width: 877px;
  }
}
