#reviews {
  /*height: 1000px;*/
  /*padding: 40px;*/
  color: #273c89;
  padding: 70px 40px 70px 40px;
}

.sectionReviewsBody {
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  flex-wrap: wrap;
}

.reviewsContent {
  background: white !important;
}

.reviewsHeader {
  display: flex;
  justify-content: space-between;
}

.reviewsTitle {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 48px;
  font-weight: 900;
  text-transform: uppercase;
}

.reviewsSocialMediaIcons img {
  max-height: 42px;
  max-width: 45px;
  margin-right: 20px;
}

.reviewsCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 50px;
  max-width: 1140px;
  width: 100%;
}

.slide-content{
  margin: 0 40px;
  overflow: hidden;
  padding: 85px 10px;
}

.swiper-navBtn{
  color: #273c89;
}

.swiper-button-next{
  right: 0;
}
.swiper-button-prev{
  left: 0;
}

.reviewCard {
  background: #ecc36c;
  width: 326px;
  /* min-width: 326px; */
  height: 360px;
  margin-right: 80px;
  box-shadow: 10px -10px 1px 1px #273c89;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px 20px 0px;
}

.reviewLogo {
  position: absolute;
  top: -35px;
}

.reviewLogo img {
  position: relative !important;
  width: 66px;
  height: 66px;
}

.reviewLogo::before {
  position: absolute;
  content: "";
  background: url(../img/reviews/reviewLogoTail.svg) no-repeat;
  width: 67px;
  height: 55px;
  top: -42px;
  left: 38px;
  z-index: 10;
}

.reviewBy {
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #273c89;
}

.review-stars-block {
  font-size: 30px;
  color: #273c89;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-block;
  margin-top: 20px;
}

.review-star {
  margin-right: 1px;
  transform: rotate(20deg);
}

.reviewDescription {
  color: #273c89;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  max-height: 140px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 5px;
  box-sizing: border-box;
  text-align: center;
  cursor: grab;
}

.reviewDate {
  font-size: 19px;
  color: #273c89;
  margin-top: 10px;
}

.loginToReviewSection {
  display: flex;
  /*justify-content: space-between;*/
  width: 100%;
  align-items: center;
  margin-top: 100px;
  padding: 0 10px;
}

.loginToReviewDiv {
  color: #273c89;
  font-size: 20px;
  font-weight: 700;
  font-family: "Aileron", Sans-serif, serif;
  border-bottom: 1px solid #000000;
  width: 70%;
  padding-bottom: 10px;
  margin-right: 20px;
  display: flex;
}

.loginToReviewDiv img {
  width: 39px;
  height: 39px;
}

.loginToReviewDiv span {
  margin-left: 13px;
  display: flex;
  align-items: center;
}

.loginToReviewButton {
  width: 30%;
  font-weight: 900;
  transition: color var(--hover-transition);
}

.loginToReviewButton:hover{
  background: #273c89;
  color: var(--hover-color);
}

.starsRating{
  width: 20%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.addReviewSection {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 80px 0px 0px 0px;
}

.addReviewSectionWrap {
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  flex-wrap: wrap;
}

.rating-group {
  display: inline-flex;
}

.rating-group label i {
  font-size: 20px;
}

.rating__icon {
  pointer-events: none;
}

.rating__input {
 position: absolute !important;
 left: -9999px !important;
}

.rating__input--none {
  display: none;
}

.rating__label {
  cursor: pointer;
  padding: 0 0.2em;
}

.rating__icon--star {
  color: orange;
}

.rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}

.rating-group:hover .rating__label .rating__icon--star {
  color: orange;
}

.rating__input:hover ~ .rating__label .rating__icon--star {
  color: #ddd;
}

/***** MOBILE *****/

.reviewsSocialMediaIcons-sm {
  display: none;
}

/* .reviewsCarousel-small {
  display: none;
} */

.reviewsSocialMediaIcons-sm a {
  padding: 15px;
}

.scrollable-element {
  scrollbar-color: red yellow; /* red is for the thumb and yellow is for the track */
}

.showMessageAfterReview{
  display: flex;
  justify-content: center;
  font-size: 30px;
  padding: 0 10px;
}

/* .carousel-control {
  opacity: -1 !important;
} */

.reviewDescription::-webkit-scrollbar {
  height: 100%;
  width: 5px;
  background: #ecc36c;
  padding-right: 5px;
  margin-right: 5px;
}

.reviewDescription::-webkit-scrollbar-thumb {
  background: #273c89;
  margin-right: 5px;
  /*-webkit-border-radius: 10px;*/
}

.reviewDescription::-webkit-scrollbar-corner {
  background: #000;
}

/* .loggedUser {
  width: auto;
  padding-right: 10px;
  white-space: nowrap;
} */

/* .addReviewField {
  width: 85%;
  outline: 0;
  background: none;
  border: none #eaeaea;
  background: #fafafa;
  box-shadow: none;
  box-sizing: border-box;
}
.addReviewField:focus {
  outline: 0;
} */

@media (min-width: 1199px) {
  .reviewsSocialMediaIcons {
    display: flex;
  }

  .reviewsSocialMediaIcons-sm {
    display: none;
  }
}

@media (max-width: 1198px) {
  .reviewsSocialMediaIcons {
    display: none !important;
  }

  .reviewsSocialMediaIcons-sm {
    display: flex;
  }

  .reviewLogo {
    top: 100px;
  }

  .slide-content{
    padding-top: 0px;
  }

  .reviewLogo::before {
    top: -180px;
  }

  .reviewLogo img{
    top: -135px;
  }

  .reviewCard {
    width: 100%;
    padding: 40px;
    margin-top: 137px;
  }

  .reviewsCarousel-small {
    display: block;
    /* margin-right: 20px; */
  }

  .reviewsSocialMediaIcons-sm {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media (max-width: 768px){
  .loginToReviewSection{
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .starsRating{
    margin-top: 30px;
  }
  .loginToReviewSection .loginToReviewButton{
    margin-top: 20px;
  }
}

@media (max-width: 420px){
  .reviewCard{
    padding: 20px;
  }
}

@media (max-width: 380px){
  .reviewCard{
    padding: 10px;  
  }
  .review-stars-block{
    font-size: 24px;
  }
}

@media (max-width: 330px){
  #reviews{
    padding: 70px 0;
  }
  .reviewsHeader{
    justify-content: center;
  }
  .loginToReviewSection{
    padding: 0 10px;
  }
  .review-stars-block{
    font-size: 20px;
  }

  .reviewDescription{
    font-size: 18px;
  }
}