.Mproduct{
    height: 15rem;
   }

   .Image{
     /* height: 60%; */
     width: 100%;
     aspect-ratio: 1.66;
   }
  
   .iconTransition {
        transition: transform 0.15s ease-in;
   }
/* 
  .prod{
    display: block;
    margin-right: 2rem;
  }
  .productItem{
    display: block;
    justify-content: center;
    width: 100%;
  }
  .product_{
    display:flex;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
  h3{
    color: #273c89;
    font-weight: lighter;
    margin-block-end: 0rem;
    margin-block-start: 0em;
    font-size: 1rem;
  }
  
  .product h3 {
    margin: 0 0 0.25rem 0;
  }
  
  .description {
    font-style: italic;
  }
  
  .price {
    margin-top: 0.25rem;
    font-weight: bold;
    color: #ad5502;
    font-size: 1.25rem;
  }
  
  
  button {
    background: #fff;
    color: #273c89;
    font-weight: bold;
    border: none;
    display: block;
    padding: 0.1rem;
    text-align: center;
    cursor: pointer;
    margin-block-end: 0rem;
    margin-block-start: 0em;
  }
  .imgs{
      width: 100%;
      z-index: 0;
      overflow: hidden;
    
  }
  .imgs img{
    max-width: 100%;
    height: auto;
  } */