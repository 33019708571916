.waitListSection {
  height: 100vh;
  background: #f3f6ff url("../img/menu/backgroundMenu-sm.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4eddd;
  font-family: 'Aileron', sans-serif;
}

.waitlistWrapper{
  max-width: 1080px;
  margin: 0 auto;
}

.waitlistContainer{
  background: #f4eddd;
  /* border: 1px solid #777; */
  padding: 70px 0;
  margin: 0 70px;
}

.queryContainer{
  padding: 0 20px;
}

.queryContainer .flexContainer{
  align-items: center;
}

.titleContainer h2{
  color: #273c89;
  font-size: 48px;
  font-weight: 800;
  text-align: left;
  padding: 4rem 2rem;
}

.waitlist-icon{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}


.icon-text{
  font-size: 24px;
  color: #273c89;
  font-weight: 800;
  margin-top: 10px;
}

.joinButton{
  font-family: "Aileron", Sans-serif;
  font-size: 18px;
  font-weight: 900;
  background-color: #273c89;
  padding: 14px 0;
  color: white;
  border: none;
  width: 100%;
  margin-top: 2rem;
  transition: color var(--hover-transition);
}

.joinButton:hover{
  color: var(--hover-color);
}

@media (min-width: 768px) and (max-width: 1200px){
  .icon-text{
    font-size: 19px;
  }

  .waitlist-icon{
    width: 30px;
    height: 30px;
  }
  
  .titleContainer h2{
    padding: 0 2rem;
    font-size: 40px;
    line-height: 1.3;
  }
}

@media screen and (max-width: 768px){

  .w50{
    width: 100%;
  }

  .pBottom-3{
    padding-bottom: 2rem;
  }

  .waitlistContainer{
    padding: 0;
  }

  .waitlistContainer.flexContainer{
    flex-direction: column;
  }

  .titleContainer h2{
    width: 100%;
    font-size: 25px;
  }

  .icon-text{
    text-align: center;
    font-size: 19px;
  }

  .waitlist-icon{
    width: 30px;
    height: 30px;
  }

  .waitInfo .flexContainer{
    flex-direction: row;
  }
}