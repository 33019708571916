#blogPage{
  min-height: 100vh;
  width: 100%;
  align-items: center;
  font-family: Aileron, sans-serif;
  color: #273c89;
  background: #F4EDDD;
}

.margin-0{
  margin: 0;
}
.pRight-2{padding-right: 2rem;}
.pLeft-2{padding-left: 2rem;}
.width45{width: 45%;}
.width50{width: 50%;}
.width55{width: 55%;}
.row-reverse{
  display: flex;
  flex-direction: row-reverse;
}

.discoverHeader{
  width: 100%;
  justify-content: center;
  padding-top: 60px;
}

.discoverHeader h1 {
  font-size: 60px;
  font-weight: 900;
  line-height: 76.8px;
}

.blogContent{
  padding: 80px 0 50px;
}

.blogImageContainer{
  height: 270px;
  background: url('../img/blog/blog_bg.jpg') no-repeat center center/cover;
}

.discoverContent{
  width: 100%;
  margin: 20px 0 0;
  padding: 0 50px;
}

.paraContent p {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.blogPost{
  width: 100%;
  padding: 0 50px;
  margin-top: 100px;
  margin-bottom: 0;
}

.postImageContainer{
  margin-right: 20px;
}

.postImageContainer,
.postContent{
  padding: 10px;
}

.postImageContainer img {
  width: 100%;
  /* height: auto; */
  object-fit: cover;
}

.postContentWrapper{
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
}

.postHeader{
  margin-bottom: 20px;
}

.postHeader h2 {
  font-size: 36px;
  font-weight: 900;
  line-height: 43.2px;
  margin-bottom: 0;
}

.tabsWrapper{
  margin-bottom: 20px;
}

.tabsWrapper a {
  color: #273c89;
  font-size: 24px;
  line-height: 28.8px;
  margin-right: 10px;
  text-decoration: underline;
}

.tabsWrapper .linkArrow{
  transform: rotate(90deg);
  font-family: monospace;
  font-size: 30px;
  display: inline-block;
  vertical-align: bottom;
  transition: 0.5s all;
  margin: 0 5px 0 5px;
  padding: 0 5px 0px 0px;
}

.activeLink{
  font-weight: 900;
}

.tabsWrapper a.activeLink .linkArrow{
  transform: rotate(270deg);
  padding: 0 5px 0 0;
}

.tabsContentWrapper{
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.tabsContentWrapper .tabContentList{
  list-style-type: none;
  text-align: left;
  margin-right: 10px;
  padding: 0;
}

.tabsContentWrapper .tabContentList li {
  margin-bottom: 5px;
}

.tabsContentWrapper.tutorial .tabContentList li {
  list-style-type: "- ";
}

/* Media Queries */
@media(max-width: 1024px){
  .blogPost,
  .discoverContent{
    padding: 0 10px;
  }
}

@media(max-width: 900px){
  .tabsWrapper a {
    font-size: 22px;
    margin-right: 5px;
  }
  .tabsWrapper .linkArrow{
    font-size: 26px;
  }
}

@media(max-width: 810px){
  .tabsWrapper a {
    font-size: 20px;
    margin-right: 0px;
  }
  .tabsWrapper .linkArrow{
    font-size: 26px;
    margin: 0 10px 0 0;
  }
}

@media(max-width: 768px){
  .postHeader h2{
    font-size: 24px;
    line-height: 28.8px;
  }
  .paraContent p {
    font-size: 16px;
    line-height: 19.2px;
  }
  .tabsContentWrapper{
    font-size: 16px;
    line-height: 19px;
  }
  .discoverHeader h1{
    font-size: 30px;
    padding: 10px;
  }
  .blogContent{
    padding: 0;
  }
  .discoverContent.containerFlex{
    flex-direction: column;
  }
  .paraContent.width50{
    width: 100%;
    padding: 0 0 15px;
  }
  .blogPost{
    margin-top: 10px;
  }
  .blogPost.containerFlex,
  .blogPost.row-reverse{
    flex-direction: column;
  }
  .postImageContainer.width45,
  .postContent.width55{
    width: 100%;
  }
  .postImageContainer img {
    height: auto;
  }
  .blogsWrapper{
    padding-bottom: 60px;
  }
}

@media (max-width: 410px){
  .tabsWrapper a {
    font-size: 18px;
  }
}