#gallerySection {
  width: 100%;
}

#galleryHeader {
  padding-top: 170px;
  padding-bottom: 15px;
  border-bottom: 1px solid #273c89;
}

#galleryHeader h1 {
  color: #273c89;
  font-size: 48px;
  max-width: 1140px;
  margin: 0 auto;
  font-weight: 800;
  letter-spacing: 0.1rem;
}

#galleryImages {
  max-width: 1140px;
  margin: 30px auto;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 357px);
  grid-auto-rows: 245px;
  gap: 45px;
  justify-content: center;
}

.gallery-img {
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
}

.gallery-img:hover {
  cursor: pointer;
  opacity: 1;
}

.imgContainer:nth-of-type(1) {
  grid-row: 1 / 3;
}

.imgContainer:nth-of-type(2) {
  grid-row: 1 / 3;
}

.imgContainer:nth-of-type(4) {
  grid-row: 2 / 4;
}

.imgContainer:nth-of-type(5) {
  grid-row: 3 / 5;
}

.imgContainer:nth-of-type(7) {
  grid-row: 4 / 6;
}

.imgContainer:nth-of-type(8) {
  grid-row: 4 / 6;
}

.imgContainer:nth-of-type(9) {
  grid-row: 5 / 7;
}

/* Gallery Swiper */
.gallery-image-swiper-container {
  padding: 0 3rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.85);
  z-index: 1000;
}

.swiper-container.swiper-container-initialized{
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
  height: 100%;
}

.photo-gallery-swiper{
  height: 100%;
}

.swiper-slide {
  border-style: solid;
  border-width: 0;
  -webkit-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: border,background,-webkit-transform;
  transition-property: border,background,-webkit-transform;
  -o-transition-property: border,background,transform;
  transition-property: border,background,transform;
  transition-property: border,background,transform,-webkit-transform;
  /* overflow: hidden; */
}

.elementor-lightbox-item{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  padding: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  margin: auto;
}

.elementor-lightbox-item.pad-none{
  padding: 0;
}

.swiper.customized-swiper {
  width: 90%;
  height: 90%;
  display: flex;
}

.gallery-image-swiper-container .swiper .slide-content {
  padding: 40px 5px;
  width: 80%;
  height: 100%;
  margin: auto;
}

.swiper.customized-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exitGallery {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
  z-index: 100;
}

.exitGallery i {
  color: #273c89;
  font-size: 36px;
}

@media (min-width: 1200px) and (max-width: 1500px){
  #galleryImages{
    grid-template-columns: repeat(3, 255px);
    grid-auto-rows: 210px;
    gap: 30px;
  }

  #galleryHeader h1{
    max-width: 835px;
  }
}

@media (max-width: 1200px){
  .swiper.customized-swiper {
    width: 100%;
    height: 90%;
  }
}

@media (min-width: 1023px) and (max-width: 1200px){
  #galleryImages {
    grid-template-columns: repeat(3, 254px);
    grid-auto-rows: 167px;
    /* gap: 25px; */
    justify-content: center;
  }

  #galleryHeader h1 {
    max-width: 860px;
    margin: 0 auto;
  }
}

@media(min-width: 768px) and (max-width: 1200px){
  .gallery-image-swiper-container .swiper .slide-content {
    width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #galleryImages {
    grid-template-columns: repeat(2, 350px);
    grid-auto-rows: 250px;
    gap: 15px;
    justify-content: center;
  }

  #galleryHeader h1 {
    max-width: 720px;
    margin: 0 auto;
  }

  .imgContainer:nth-of-type(3) {
    grid-column: 1;
    grid-row: 3 / 4;
  }

  .imgContainer:nth-of-type(4) {
    grid-column: 2;
    grid-row: 3 / 5;
  }

  .imgContainer:nth-of-type(5) {
    grid-column: 1;
    grid-row: 5 / 7;
  }
  .imgContainer:nth-of-type(6) {
    grid-column: 1;
    grid-row: 4 / 5;
  }
  .imgContainer:nth-of-type(7) {
    grid-column: 2;
    grid-row: 5 / 7;
  }
  .imgContainer:nth-of-type(8) {
    grid-column: 1;
    grid-row: 7 / 9;
  }
  .imgContainer:nth-of-type(9) {
    grid-column: 2;
    grid-row: 7 / 9;
  }
}

@media (max-width: 768px){
  #galleryImages {
    grid-template-columns: 1fr;
    grid-auto-rows: 200px;
    gap: 15px;
  }

  #galleryHeader h1 {
    max-width: 360px;
    margin: 0 auto;
  }
  .gallery-image-swiper-container {
    padding: 0;
  }
  .swiper.customized-swiper .swiper-slide{
    align-self: center;
  }
  .gallery-image-swiper-container .swiper .slide-content {
    height: 70%;
  }
  .exitGallery {
    top: 3%;
    right: 20px;
  }
}