#landingPage{
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url('../img/backgroundBlog2.png');
}

.containerFlex{
  display: flex;
  flex-direction: row;
}

.containerFlexColumn{
  display: flex;
  flex-direction: column;
}

.pageHeader {
  width: 100%;
  text-align: center;
  padding: 2rem 0;
  justify-content: center;
  background: #fff;
}

.welcomeLogoImgContainer{
  width: 220px;
  height: 108px;
}

.welcomeLogoImgContainer img {
  object-fit: cover;
  width: 88%;
  height: 100%;
}

/* Restaurant Section */
.restaurantSection .elementWrapper{
  display: flex;
  width: 100%;
}

.pageDescription{
  justify-content: flex-start;
}

.descriptionHeading{
  padding: 2rem 0;
}

.pageDescription h2 {
  color: #273c89;
  font-size: 42px;
  font-weight: 700;
}

.locationsBox{
  width: 100%;
  max-width: 1140px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 3rem;
}

.layoutContainer{
  position: relative;
  margin: 0 auto;
  /* width: 30%; */
  transition: transform 0.5s ease;
}

.layoutContainer:hover{
  cursor: pointer;
  transform: translateY(-15px);
}

.layoutImageContainer{
  width: 90%;
}

.layoutImageContainer img {
  height: 353px;
  width: 100%;
  object-fit: cover;
}

.layoutLocationsContainer {
  background: #273c89;
  padding: 2rem 0 2rem 2rem;
  width: 100%;
  position: absolute;
  bottom: -15px;
  left: -50px;
}

.locationsIcon{
  margin-right: 1rem;
}

.layoutLocationsContainer.northMyrtleBeach{
  left: -17px;
}

.layoutLocationsContainer.wilmington{
  left: 15px;
}

.layoutLocationsContainer .location {
  display: flex;
  flex-direction: column;
  margin-right: 0;
}

.layoutLocationsContainer .location .address h3 {
  font-size: 19px;
  font-weight: 900;
  margin-bottom: 0;
}

.layoutLocationsContainer .location .address p {
  font-size: 16px;
  margin-top: 10px;
}

/* Media Queries */
@media screen and (max-width: 1240px) {
  #landingPage{
    background: url('../img/backgroundBlog2.png') center center/cover;
  }
  .locationsBox{
    max-width: 1040px;
  }

  .descriptionHeading{
    padding: 2rem;
  }
}

@media (max-width: 1200px) {
  .layoutLocationsContainer .location .address {
    margin-left: 0;
    align-items: flex-start;
  }
}

@media (min-width: 1024px) and (max-width: 1124px) {
  .locationsBox{
    max-width: 980px;
  }
}

@media (max-width: 1024px){
  .locationsBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .layoutContainer{
    width: 50%;
    height: 450px;
  }
  
  .layoutImageContainer{
    width: 100%;
    height: 400px;
  }
  
  .layoutLocationsContainer{
    position: relative;
    left: -45px;
    bottom: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    margin-top: -100px;
    justify-content: flex-start;
  }

  .layoutLocationsContainer.northMyrtleBeach,
  .layoutLocationsContainer.wilmington {
    left: -45px;
  }

  .layoutContainer.layout-nmb {
    margin-bottom: 5rem;
  }

  .layoutLocationsContainer .location .address {
    margin-top: 0;
    text-align: left;
  }

  .layoutLocationsContainer .location .locationsIcon {
    margin-right: 0;
  }
}

@media (max-width: 500px){
  .pageDescription h2{
    font-size: 38px;
  }
  .layoutContainer{
    width: 65%;
  }
  .layoutLocationsContainer{
    padding: 2rem;
  }
}