@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .category {
    animation: fadeIn 0.4s ease-in-out; /* Adjust the duration as needed */
  }
  
  .iconTransition {
    transition: transform 0.15s ease-in;
  }
  
  .selectWithTransition {
    transition: all 0.3s ease;
  }
/* .page{
    margin-left: 8.5rem;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 1rem;
}
.category {
    width: 50%;
    margin-right: 3rem;
    display: flex;
    align-items: center;
    color: #273c89;
  }
  .select {
    width: 65%;
    border: none;
    background: inherit;
    margin-left: 10px;
    color: #273c89;
    font-weight: bold;
    margin-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #273c89;
    outline: none;
    cursor: pointer;
  }
  .search{
    display: flex;
    margin-right: 1rem;

  }
  .search input{
    border-bottom: 1px solid #273c89;
    border-top:none;
    border-left:none;
    border-right:none;
    outline: none;

  }
  .cart_btn{
    display: flex;
    margin-right: 3rem;

  }










  .button:hover,
  .button:active {
    background-color: #2c0d00;
  }
  
  .icon {
    width: 1.35rem;
    height: 1.35rem;
    margin-right: 0.5rem;
  }
  
  .badge {
    
    border-radius: 25px;
   
    font-size: 0.9rem;
    
    position: relative;
    top: 1ex;

  } */
