#sidebar, 
#side-locations {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  transition: all ease-in-out 0.5s;
  z-index: 100;
  padding: 0 15px;
  background-color: #273c89;
  color: white;
}

.sidebarLogo img {
  display: block;
  width: 150px;
  margin: 115px auto 30px;
}

.navbar {
  padding: 30px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  margin-top: -22px;
}

.navbar * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar > ul > li {
  position: relative;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid transparent;
}

.navbar > ul > li:hover {
  border-bottom: 1px solid #96784a;
  text-align: center;
}

#side-locations li:hover{
  border-bottom: 1px solid transparent;
}

#side-locations li a:hover{
  color: #3e56ad;
}

.nav-link,
.nav-link:focus {
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  padding: 6px 15px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-weight: 900;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 14px;
  text-transform: uppercase;
}

.nav-link:hover {
  color: white;
  text-decoration: none;
}

.itemMenu{
  border-bottom: 1px solid transparent;
  padding: 6px 15px;
  margin-bottom: 8px;
}

.itemMenu:hover{
  border-bottom: 1px solid #96784a;
}

.itemMenu a{
  text-decoration: none;
  cursor: pointer;
}

.itemMenu .sub-btn{
 font-size: 14px;
 font-weight: 800;
}

.itemMenu a:hover{
  color: #fff;
}

.sub-menu{
  /* display: none; */
}

.sub-menu a{
  display: block;
  margin-top: 10px;
  font-size: 14px;
}

.rotate{
  transform: rotate(90deg);
 }

.itemMenu .dropdown{
  transition: 0.3s ease;
}

.h-100{
  height: 100%;
}

.hidden{
  display: none;
}

.sidebarMobile {
  width: 100%;
  height: 253px;
  background-color: #273c89;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  display: none;
}

.mobile-navbar-toggle {
  position: absolute;
  right: 39px;
  top: 192px;
  z-index: 101;
  border: 0;
  font-size: 42px;
  transition: all 0.4s;
  outline: none !important;
  color: #fff;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 50px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .sidebarLogo {
    display: none;
  }

  .sidebarMobile {
    display: flex;
  }

  #sidebar {
    top: 190px;
    width: 100%;
    position: absolute;
    height: fit-content;
    /*bottom: 200px;*/
  }

  .sx {
    left: -300px !important;
    width: 300px !important;
  }

  #pageContainer {
    padding-left: 0;
  }

  #side-locations{
    position: initial;
    margin: auto;
    height: 90%;
  }

  #sidebar.sx #side-locations.sx{
    margin-left: -15px;
  }
}

@media (min-width: 1199px) {
  .sx {
    width: 300px !important;
  }

  #sidebar {
    width: 300px !important;
  }
}
