.loginSection{
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #7785b7;
  position: relative;
  font-family: 'Aileron', sans-serif;
  /* display: none; */
}

.showUp{
  display: flex;
}

.hidden{
  display: none;
}

.loginContainer{
  border: 20px solid #273c89;
  padding: 12px;
  position: relative;
  background: #fff;
}

.loginInnerContainer{
  display: flex;
  border: 5px solid #273c89;
  margin: 15px;
  padding: 25px;
}

.registerContainer{
  width: 40%;
  justify-content: center;
  padding-right: 1em;
}

.registerContainer .blueberryLogoSvg{
  width: 56px;
  height: 64px;
}

.required{
  color: red;
  font-weight: 900;
}

.registerText{
  font-size: 30px;
  line-height: 36px;
  color: #273c89;
  margin-top: 18px;
}

.registerText .boldText{
  font-weight: 700;
}

.registerFormContainer{
  width: 60%;
  justify-content: space-between;
}

.registerForm{
  width: 48%;
}

.registerForm h2,
.loginForm h2{
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  color: #273c89;
  margin-bottom: 0.7em;
}

.registerFormLabelInput{
  display: flex;
  flex-direction: column;
}

.registerFormLabelInput label {
  font-size: 16px;
  line-height: 19.2px;
  color: #273c89;
  opacity: 0.5;
  font-weight: 400;
  margin-bottom: 5px;
}

.registerFormLabelInput input {
  height: 46px;
  border: 1px solid rgb(39 60 137 / 50%) !important;
  font-size: 16px;
  width: 100%;
  padding: 7px;
  margin-bottom: 10px;
  outline: 0;
}

.registerFormLabelInput input::placeholder{
  color: #273c89;
  opacity: 0.5;
}

.registerFormLabelInput.lostPassword{
  margin-top: -15px;
}

.registerFormButton{
  border: none;
  font-weight: 700;
  background: #273c89;
  padding: 13px 10px 14px;
  font-size: 16px;
  line-height: 19.2px;
  width: 100%;
  color: white;
  margin-top: 15px;
}

#repeatPasswordInput.notMatch{
  border: 1px solid red !important;
}

.loginForm{
  width: 48%;
}

.loginFormButton{
    background: #273c89;
    padding: 13px 10px 14px;
    font-size: 16px;
    line-height: 19.2px;
    font-weight: 700;
    width: 100%;
    color: #ffffff;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
    border: none;
}

.lost-bg{
  background: #ecc36c;
  font-size: 15px;
  color: #273c89;
  border: none;
  font-weight: 900;
}

.closeLoginIcon{
  background: #273c89;
  font-size: 26px;
  width: 55px;
  height: 55px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: -19px;
  right: -15px;
  font-weight: 900;
}

.closeIcon{
  padding-top: 10px;
  padding-right: 5px;
}

.registerFormLabelInput .rememberMeContainer{
  position: relative;
  padding-left: 35px;
  margin: 12px 0;
  color: #273c89;
  opacity: 1;
}

.rememberMeContainer input{
  position: absolute;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 27px;
  width: 27px;
  border: 1px solid rgb(39 60 137 / 50%);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rememberMeContainer input:checked ~ .checkmark:after {
  display: block;
}

.rememberMeContainer .checkmark:after {
  left: 9px;
  top: 2px;
  width: 8px;
  height: 16px;
  border-bottom: 2.5px solid #273c89;
  border-right: 2.5px solid #273c89;
  opacity: 1;
  transform: rotate(45deg);
}

.loginError{
  display: flex;
  background: #ffdfd3;
  border-top: 3px solid #c20808;
  align-items: center;
  text-align: center;
  width: 60%;
  align-self: end;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 2rem;
}

.loginError i {
  color: #c20808;
  font-size: 22px;
  padding-right: 10px;
}

.loginError.updateSuccess{
  color: #fff;
  border-top: 3px solid #273c89;
  background: #7785b7;
}

.loginError.updateSuccess i {
  color: #6cd632;
}

.lostPasswordForm{
  display: flex;
  flex-direction: column;
}

.lostPasswordForm p {
  font-size: 16px;
  opacity: 0.8;
  font-weight: 400;
}

.lostPasswordForm label {
  font-size: 18px;
  color: #273c89;
  opacity: 0.7;
}

.lostPasswordForm input {
  border: 1px solid #273c89;
  padding: 10px;
  color: #273c89;
  font-size: 16px;
  outline: none;
}

.lostPasswordForm .registerFormLabelInput button{
  width: 30%;
  font-size: 18px;
}

.backToForm{
  font-size: 18px;
  color: #273c89;
  font-weight: 700;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  cursor: pointer;
  width: fit-content;
  transition: transform 0.2s ease;
}

.backToForm i {
  font-size: 22px;
  margin-right: 1rem;
}

.backToForm:hover{
  transform: scale(1.05);
}

@media (max-width: 1024px){
  .loginSection{
    height: 100%;
  }
}

@media (min-width: 900px) and (max-width: 1024px){
  .loginContainer{
    width: 90%;
  }

  .loginInnerContainer{
    flex-direction: column;
    padding: 20px;
  }

  .registerContainer{
    align-items: center;
  }
  
  .breakParagraph{
    display: block;
  }

  .registerText p {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .loginContainer{
    width: 100%;
  }
  .loginInnerContainer{
    flex-direction: column;
    padding: 25px;
    margin: 15px;
  }

  .registerContainer,
  .registerFormContainer{
    width: 100%;
  }

  .registerFormContainer {
    flex-direction: column-reverse;
  }

  .registerForm {
    width: 100%;
    margin-top: 25px;
  }

  .loginForm {
    width: 100%;
  }

  .registerContainer{
    align-items: center;
  }
  
  .breakParagraph{
    display: block;
  }

  .registerText p {
    text-align: center;
  }
}

@media (max-width: 700px){
  .loginError{
    font-size: 16px;
  }
  .lostPasswordForm .registerFormLabelInput .loginFormButton{
    width: 40%;
  }
}

@media (max-width: 620px){
  .loginError{
    width: 100%;
  }
  .lostPasswordForm .registerFormLabelInput .loginFormButton{
    width: 50%;
  }
}

@media (max-width: 500px) {
  .registerText  {
    font-size: 22px;
  }
  .loginInnerContainer{
    padding: 10px;
    margin: 0;
  }
}

@media (max-width: 440px){
  .lostPasswordForm .registerFormLabelInput .loginFormButton{
    width: 60%;
    font-size: 16px;
    padding: 10px;
  }
}