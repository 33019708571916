.card-header-checkout {
    height: 3rem;
    color: black;
    font-weight: bold;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  }

  .button-checkout{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkoutAddNewAdressButton{
    background-color: #273c89;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    /* width: 100%; */
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .proceedToPaymentButton{
    background-color: #273c89;
    color: white;
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 24px;
    font-weight: 400;
  }