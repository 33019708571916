footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  background-color: #273c89;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  overflow: hidden;
  z-index: 101;
}

/* #footer{
  position: fixed;
  bottom: 0;
} */

.footerTop {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footerBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 15px;
}

.footerLeft {
  color: #ffffff;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  line-height: 24px;
  width: 50%;
  padding: 15px 100px;
}

.footerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footerRight img {
  margin-top: -8px;
}

.footerRight h3 {
  width: 150px;
  margin-left: 25px;
}

.footerRight h3 {
  color: #ffffff;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 900;
  line-height: 24px;
}

.appleStore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.androidStore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 2px;
  color: white;
}


.address h3 a {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 800;
}

.address p {
  margin-top: 20px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  color: white;
  margin-right: 42px;
}

.followUs {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  font-size: 20px;
  font-weight: 800;
  margin-top: -41px;
}

.locationIcon img {
  max-width: 70%;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.followUsIcons {
  margin-top: 20px;
}

.followUsIcons i {
  margin-right: 10px;
}

@media (max-width: 1199px) {
  .followUsIcons i {
    margin-right: 27px;
    font-size: 32px;
  }

  .address {
    margin-left: 41px;
    align-items: center;
  }

  .footerTop {
    flex-direction: column;
  }

  .footerLeft {
    width: 100%;
    text-align: center;
  }

  .appleStore {
    flex-direction: column;
  }

  .androidStore {
    flex-direction: column;
  }

  .location-1 {
    display: none;
  }

  .location-2 {
    display: none;
  }

  .location-3 {
    display: none;
  }

  .footerBottom {
    flex-direction: column-reverse;
    margin-top: 80px;
  }

  .footerRight {
    margin-top: 30px;
  }

  .footerRight img {
    margin-top: -8px;
    margin-left: 17px;
  }

  .location-4 {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
  }

  .location-4 img {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 92px;
    margin-top: 22px;
  }

  .followUs {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .footerLeft {
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
  }

  .appleStore h3 {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .androidStore h3 {
    font-size: 16px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .followUs {
    justify-content: center;
    align-items: center;
  }

  .address {
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .followUsHeading {
    font-size: 16px;
  }
}