.valuesPage{
  min-height: 100vh;
  width: 100%;
  align-items: center;
  font-family: Aileron, sans-serif;
  color: #273c89;
  padding-bottom: 60px;
}

.elementWrapper{
  max-width: 1140px;
  margin: auto;
}

.bg-gold{background: #f4eddd;}

.bg-gray{background: #f3f6ff;}

.flex-end{
  display: flex;
  justify-content: end;
}
/* Values Container */
.valuesContainer{
  padding: 80px 50px 50px;
  justify-content: center;
}

.valuesBanner {
  text-align: center;
}

.valuesBanner img{
  width: 100%;
}

.linksContainer a {
  color: #273c89;
  text-decoration: none;
}

.linksContainer{
  justify-content: space-between;
  padding-bottom: 30px;
  align-items: center;
}

.valuesParagraph {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 1.6em;
}

.valuesLink a:hover > img {
  transform: scale(1.05);
}

.valuesText{
  font-size: 48px;
  font-weight: 900;
  padding-left: 1rem;
}

.teamText{
  font-size: 30px;
  font-weight: 900;
  display: inline-block;
}

.rightArrowImg{
  margin-bottom: -27px;
  margin-left: -7px;
}

.itemDescription{
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 60px 10px;
}

.itemDescription p {
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
}

.itemDescription h3 {
  font-size: 20px;
  font-weight: 700;
}

.itemImage img{
  height: 100%;
  object-fit: cover;
}

/* Media Query */
@media screen and (max-width: 1200px) {
  .valuesContainer{
    padding: 80px 35px 50px;
  }
  .valuesDescription .elementWrapper{
    max-width: fit-content;
    padding: 0 30px;
  }
}
@media screen and (max-width: 768px) {
  .valuesContainer{
    padding: 40px 30px;
  }
  .linksContainer.flexContainer{
    flex-direction: row;
  }
  .linksContainer .valuesTitle {
    flex-direction: row;
    align-self: flex-start;
  }
  .linksContainer .valuesLink {
    align-self: baseline;
  }
  .valuesLink a h3{
    margin-right: -40px;
  }
  .valuesText{
    font-size: 30px;
  }
  .teamText{
    font-size: 25px;
  }
  .itemDescription{
    padding: 35px 20px;
  }
  .itemDescription h3 {
    font-size: 17px;
    font-weight: 800;
  }
  .itemDescription p {
    text-align: justify;
    line-height: 21.6px;
  }
  .valueItems.flexContainer{
    flex-direction: column;
  }
  .valueItems > div{
    width: 100%;
  }
  .itemImage{
    width: 358px;
    height: 316px;
  }
  .itemImage img{
    width: 100%;
  }
  .valuesBanner img {
    object-fit: cover;
    height: 400px;
  }
}