/* .error_text {
    color: #b40e0e;
    font-size: 0.3em;
  }
  .input_error{
    display:block;
    margin-bottom: -0.9rem;
  }
  .modal_items{
  display:inline-flex;
  }
  .modal_input input{
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  max-width: 100%;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
  margin-block: 1rem;
  min-width: 12rem;
  }
  .modal_input select{
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  max-width: 100%;
  margin-right: 1rem;
  margin-bottom: 0.2rem;
  margin-block: 1rem;
  min-width: 12rem;
  }
  
  @media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
  }
  .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
  }
  .modal {
  position: fixed;
  top: 20vh;
  left: 25%;
  background-color: white;
  padding: 1rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  }
  .header {
  background: #7990eb;
  padding: 1rem;
  text-align: center;
  }
  
  .header h2 {
  margin: 0;
  color: white;
  }
  
  .content {
  padding: 1rem;
  }
  
  .actions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  }
  .total{
    display: block;
    text-align: end;
  }
  .checkoutBtn{
    display: flex;
    justify-content: flex-start;
  }
  .closeBtn{
    display: flex;
    justify-content: flex-end;
  } */
  .container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 2em;
    
  }
  .image {
    width: 200px;
    aspect-ratio: 1.66;
    margin-right: auto;
  }