/* .item {
    margin: 1rem 0;
    padding: 1rem;
  }
  
  .item h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1.75rem;
  }
  
  .item header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quantity span {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .price {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .itemprice {
    font-weight: normal;
    font-size: 1rem;
    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 0;
    background-color: #7990eb;
  }
  
  .actions button {
    background-color: transparent;
    border-right: 1px solid white;
    margin-left: 0.5rem;
    padding: 0.15rem 1rem;
    color: white;
  }
  
  .actions button:hover,
  .actions button:active {
    background-color: #4b4b4b;
    color: white;
  }
  .imgs{
    margin-inline-start: -41rem;
    margin-inline-end: -44rem;
    margin-bottom: -5rem;
  }
  .imgs img{
    max-width: 15%;
    height: auto;
  }
  .pad{
    padding: 2rem;
  }
  .list_item{
    display: inline-flex;
    margin-inline-start: -38em;
  } */   
  .iconTransition {
    transition: transform 0.25s ease-in;
  }

  .item{
    height: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }
  .image{
    height: 4rem !important;
    aspect-ratio: 1.66 !important;
    margin: 0 !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: 1rem;
  }
  .subContainer {
    display: flex;
    flex-direction: row 
  }