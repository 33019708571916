.emptyPage {
  height: 100vh;
}

.pTop-1{padding-top: 1rem;}
.pTop-2{padding-top: 2rem;}
.pTop-3{padding-top: 3rem;}
.pBottom-1{padding-bottom: 1rem;}
.pBottom-2{padding-bottom: 2rem;}
.pBottom-3{padding-bottom: 3rem;}

.mBottom-1{margin-bottom: 1rem;}
.mBottom-2{margin-bottom: 2rem;}
.mBottom-3{margin-bottom: 3rem;}

.pLR-1{padding: 0 1rem;}
.pLR-2{padding: 0 2rem;}
.pLR-3{padding: 0 3rem;}

.w50{width: 50%;}
.w100{width: 100%;}

.relativeElement{position: relative;}

.menuIcons{margin-left: 10px;}

.size27{
  width: 27px;
  height: 27px;
}

.iconNames{margin-right: 10px;}

.centeredItems{
  justify-content: center;
  align-items: center;
}

#pageContainerRasiol {
  padding-left: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('../img/menu/backgroundMenu-sm.png') no-repeat center center/cover;
  background-color: #f4eddd;
  color: #273C89;
  font-family: "Aileron", Sans-serif;
}

#homeSection{
  padding-top: 3em;
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  /* border: 8px solid greenyellow; */
}

#homeSection p{
  font-weight: 400;
}

#menuHeader{
  height: 300px;
  width: 100%;
  background: url('../img/menu/headerMenu.jpg') no-repeat center center/cover;
}

#menuPageContainer{
  /* border: 5px solid rgb(78, 0, 0); */
  height: 100%;
  margin-bottom: 5.5rem;
}

#menuContainerWrapper{
  border: 1px solid #273C89;
  padding: 3em;
  width: 100%;
  height: 100%;
  position: relative;
  background: #f4eddd;
}

#menuContainer{
  border: 1px solid #ecc36c;
  padding: 2em 4em;
  height: 100%;
}

#menuToggleBtn{
  color: #fff;
  text-decoration: none;
}

.leadHeader{
  color: #ecc36c;
  font-size: 36px;
  font-weight: 800;
  line-height: 43.2px;
  text-shadow: 3px 2px 0px #273c89;
}

.leadParagraph{
  font-size: 18px;
}

#homeSection h3{
  font-size: 25px;
  font-weight: 800;
  margin-bottom: 1rem;
}

#menuTitle{
  color: #273C89;
}

/* #menuInnerContainer{
  border: 3px solid green;
} */

#borderAround{
  /* border: 3px solid crimson; */
  margin-bottom: 1em;
}

.textCenter{
  text-align: center;
}

.flexContainer{
  display: flex;
}

.flex-reverse{flex-direction: row-reverse;}

.flexContainer p{
  text-align: left;
}

.flexColumnContainer{
  display: flex;
  flex-direction: column;
}

.menuTitle{
  position: absolute;
  top: -95px;
  left: 23%;
}

.lunchTitle{
  left: 28%;
}

.cocktailsTitle{
  left: 15%;
}

.dishInfoParagraph{
  padding-right: 4rem;
}

/* Breakfast */
.BreakFastMenuTitle {
  background-color: #ecc36c;
  padding: 4em 10em;
}

.BreakFastMenuTitle > h2 {
  color: #273C89;
  font-size: 45px;
  font-weight: 800;
}

.breakfastServing{
  padding-top: 6rem;
}

.breakfastServing h2{
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 1.5rem;
}

.breakfastBottom{
  padding: 8em;
}

.otherMenuItems{
  font-size: 18px;
}

/* Responsive Media Queries */

/* Mobile */
@media screen and (max-width: 620px){
  #pageContainerRasiol{
    padding-left: 0;
  }

  .sidebarMobile{height: 200px;}
  .mobile-navbar-toggle{
    top: 150px;
    right: 30px;
    font-size: 35px;
  }

  .menuTitle{
    top: -80px;
    left: 15%;
  }

  .lunchTitle{
    left: 25%;
  }

  .BreakFastMenuTitle {
    padding: 2em 3.5em;
  }

  .alignLeft{
    text-align: left;
  }

  .BreakFastMenuTitle h2{
    font-size: 22px;
  }

  #menuHeader{
    height: 200px;
  }

  #menuContainerWrapper{
    padding: 0;
    border: 0;
  }

  #menuContainer{
    padding: 3em;
    border: 0;
  }

  .flexContainer{
    flex-direction: column;
  }

  .w50{
    width: 100%;
  }

  .pTop-3{padding-top: 1rem;}

  .breakfastServing h2{
    font-size: 26px;
    font-weight: 800;
  }

  #homeSection h3{
    font-size: 18px;
    /* font-weight: 800;
    margin-bottom: 1rem; */
  }

  .breakfastServing {
    padding-top: 0;
  }

  .leadHeader{
    font-size: 25px;
  }

  .leadHeader.mobSize{
    padding-top: 2rem;
  }

  .leadParagraph{
    font-size: 16px !important;
  }

  .dishInfoParagraph {
    padding-right: 0;
    font-size: 15px !important;
  }

  .flexContainer.rowItems{
    flex-direction: row;
  }

  .iconNames{
    margin-right: 0;
    width: 30px;
    height: 30px;
    align-self: center;
  }

  .flexContainer.pLR-1{
    padding-bottom: 1rem;
  }

  .breakfastBottom{
    padding: 6em 0 0;
  }
}

/* --- */
@media screen and (max-width: 420px){
  .cocktailsTitle{
    left: 5% !important;
  }

  .cocktailsMenuTitle h2{
    font-size: 20px;
  }

  .lunchTitle{
    left: 20%;
  }
}

/* Tablets */
@media screen and (min-width: 620px) and (max-width: 940px){
  #pageContainerRasiol{
    padding-left: 0;
  }

  .sidebarMobile{height: 200px;}
  .mobile-navbar-toggle{
    top: 150px;
    right: 30px;
    font-size: 35px;
  }

  #menuContainerWrapper{
    padding: 0;
    border: 0;
  }

  #menuContainer{
    padding: 3em;
    border: 0;
  }

  .BreakFastMenuTitle {
    padding: 3em 5.5em;
  }

  .BreakFastMenuTitle h2 {
    font-size: 36px;
  }

  .dishInfoParagraph{
    font-size: 16px;
  }

  .flexContainer.rowItems{
    flex-direction: column;
    padding-bottom: 1rem;
    /* align-self: flex-start; */
  }

  .cocktailsTitle{
    /* top: -180px !important; */
    left: 15% !important;
  }

  .cocktailsMenuTitle h2{
    font-size: 35px;
  }
}

@media screen and (min-width: 620px) and (max-width: 730px){
  .cocktailsTitle{
    /* top: -180px !important; */
    left: 10% !important;
  }

  .cocktailsMenuTitle h2{
    font-size: 30px;
  }
}

@media screen and (min-width: 940px) and (max-width: 1200px){
  #pageContainerRasiol{
    padding-left: 0;
  }

  .dishInfoParagraph{
    font-size: 16px;
  }

  .cocktailsTitle{
    left: 10% !important;
  }
}

/* Large Screens */
@media screen and (min-width: 1200px) and (max-width: 1580px){
  .dishInfoParagraph{
    font-size: 16px;
  }

  .cocktailsTitle{
    left: 15% !important;
  }

  .cocktailsMenuTitle h2{
    font-size: 40px;
  }
}

@media screen and (min-width: 1580px) and (max-width: 2400px){

  #homeSection{
    max-width: 2000px;
    padding: 2rem 4rem;
  }

  #homeSection h3{
    font-size: 32px;
  }

  .menuTitle{
    left: 30%;
  }

  .lunchTitle{
    left: 35%;
  }

  .leadHeader{
    font-size: 40px;
    padding-bottom: 1rem;
  }

  .leadParagraph{
    font-size: 25px !important;
  }

  .dishInfoParagraph{
    font-size: 25px;
  }

  .otherMenuItems{
    font-size: 25px;
  }

  .menuIcons{
    margin-left: 1rem;
    align-self: center;
  }

  .menuIcons .size27{
    margin-top: -10px;
    width: 35px;
    height: 35px;
  }

  .iconNames{
    width: 40px;
    height: 40px;
  }
}

@media screen and (min-width: 1580px) and (max-width: 2000px){
  .cocktailsTitle{
    /* top: -180px !important; */
    left: 20% !important;
  }
}

@media screen and (min-width: 2400px) and (max-width:3500px) {

  #menuContainerWrapper{
    padding: 5rem;
  }

  .menuTitle{
    top: -140px;
    left: 30%;
  }

  .cocktailsTitle{
    top: -140px !important;
    left: 25% !important;
  }

  .BreakFastMenuTitle{
    padding: 6em 14em;
  }

  .BreakFastMenuTitle h2{
    font-size: 70px;
  }

  .breakfastServing h2{
    font-size: 55px;
  }

  #menuContainer{
    padding: 4em 8em;
  }

  #homeSection{
    max-width: 2400px;
    padding: 4rem;
  }

  #homeSection h3{
    font-size: 50px;
  }

  .leadHeader{
    font-size: 60px;
    padding-bottom: 4rem;
    line-height: 1.2;
  }

  .leadParagraph{
    font-size: 40px !important;
    letter-spacing: 0.2rem;
  }

  .dishInfoParagraph{
    font-size: 38px;
    letter-spacing: 0.3rem;
  }

  .otherMenuItems{
    font-size: 40px;
  }

  .menuIcons{
    margin-left: 2rem;
    align-self: center;
  }

  .menuIcons .size27{
    width: 45px;
    height: 60px;
  }

  .iconNames{
    width: 50px;
    height: 60px;
  }
}

@media screen and (min-width: 3500px){

  #menuContainer{
    padding: 4em 8em;
  }

  #homeSection h3{
    font-size: 57px;
    padding: 2rem 0;
  }

  #homeSection{
    max-width: 2800px;
    padding: 4rem;
  }

  #menuContainerWrapper{
    padding: 6rem;
  }

  .cocktailsTitle{
    top: -180px !important;
    left: 25% !important;
  }

  .menuTitle{
    top: -180px;
    left: 30%;
  }

  .lunchTitle{
    left: 35%;
  }

  .breakfastServing{
    padding-top: 10rem;
    padding-bottom: 4rem;
  }

  .BreakFastMenuTitle{
    padding: 8em 16em;
  }

  .BreakFastMenuTitle h2{
    font-size: 80px;
  }

  .breakfastServing h2{
    font-size: 60px;
    padding-bottom: 2rem;
  }

  .leadHeader{
    font-size: 70px;
    padding-bottom: 3rem;
    line-height: 1.2;
  }

  .leadParagraph{
    font-size: 48px !important;
    letter-spacing: 0.2rem;
  }

  .dishInfoParagraph{
    font-size: 45px;
    letter-spacing: 0.2rem;
  }

  .otherMenuItems{
    font-size: 45px;
  }

  .menuIcons{
    margin-left: 2rem;
    align-self: center;
  }

  .menuIcons .size27{
    width: 60px;
    height: 70px;
  }

  .iconNames{
    width: 60px;
    height: 70px;
  }
}