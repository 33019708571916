/* .order{
  display: block;
}
.header {
background: #b5cbf7;
padding: 1rem;
text-align: center;
color: black;
}

.address {
  margin: 0;
  border:1px solid #383734;

}

.payment {
  margin: 0;
  border:1px solid #383734;
  }
.credit_card{
    display: block;
}
.coupons{
  display: block;
}
h4{
  text-decoration: underline;
  color: rgb(61, 200, 218);
}
.row{
  display: inline-flex;
}
.columns{
  display: inline-grid;
}
.user_address{
  
  padding-inline-start: 14rem;

}
.user_address_head{
  float: left;
}
.btn{
  margin-left: 23rem;
  float: right;
  text-align: right;
 
  justify-content: flex-end;
}
.btn_save{
  
  color:darkblue
}
.btn_save:disabled{

 cursor: not-allowed;;
 color:#989793
}
button.right{

}
.single_user_address{
  display: "block";
  border-bottom:1px solid #b3dbe3;

}
.payment_row{
  display: flex;
}
a{
  color:blue
} */
.button{
  width: 50%;
  margin-right: auto;
}