/* contact us  */
.contactUsSection {
  /* height: 100vh; */
  padding: 170px 70px 0px 170px;
}

.ContactUsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* border: 1px solid red; */
}

.ContactUsHeading {
  color: #273c89;
  font-family: "Aileron", Sans-serif;
  font-size: 48px;
  font-weight: 900;
  line-height: 57.6px;
  margin-bottom: 30px;
}

.MytreBeach {
  color: #273c89;
  font-family: "Aileron", Sans-serif;
  font-size: 48px;
  font-weight: 900;
  line-height: 57.6px;
  margin-bottom: 30px;
  position: relative;
  margin-left: 58px;
}

.ContactFormInputDiv input {
  background-color: #ffffff;
  border-color: #273c89;
  font-family: "Aileron", Sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 21.6px;
  color: #273c89;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.form-control::-webkit-input-placeholder {
  color: rgb(170, 186, 214);
  /* font-size: 12px; */
}

.ContactFormInputDiv textarea {
  background-color: #ffffff;
  border-color: #273c89;
  font-family: "Aileron", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  color: #273c89;
  margin-bottom: 10px;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.LeftContactContainer {
  /* padding: 20px; */
  /* border: 1px solid green; */
  width: 25%;
}

.RightContactContainer {
  /* border: 1px solid hotpink; */
  width: 60%;
}

.RightContactContainer iframe {
  width: 100%;
}

.SendButtonContactForm {
  font-size: 18px;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 21.6px;
  background-color: #273c89;
  color: #ffffff;
  flex-basis: 100%;
  min-height: 40px;
  border-radius: 2px;
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  border: none;
  width: 100%;
}

::-webkit-input-placeholder {
  /* Edge */
  color: red;
}

.ContactFormInputDiv input:focus,
.ContactFormInputDiv textarea:focus {
  border-color: #273c89;
  /* border: none; */
  box-shadow: none;
}

.MytreBeach img {
  position: absolute;
  left: -65px;
  top: 13px;
}

.LastSectionOfContactUs {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  /* border: 1px solid red; */
  margin-top: 60px;
  margin-bottom: 30px;
}

.rigthLastSectionOfContactUs {
  padding: 10px 10px 10px 20px;
  width: 100%;
  /* border: 1px solid green; */
}

.leftLastSectionOfContactUs {
  width: 100%;
}

.dayTimeOfOpen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid gold; */
  /* width: 100%; */
  border-bottom: 1px solid #fcb900;
  padding-left: 30px;
  padding-right: 40px;
  padding-top: 10px;
}

.rigthLastSectionOfContactUs h2 {
  color: #273c89;
  font-family: "Aileron", Sans-serif;
  font-size: 30px;
  font-weight: 900;
  line-height: 57.6px;
}

.day {
  font-family: "Aileron", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #273c89;
  width: 100%;
  /* border: 1px solid red; */
  margin-left: 28px;
}

.time {
  font-family: "Aileron", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #273c89;
  width: 100%;
  /* margin-left: 77px; */

  /* border: 1px solid green; */
}

.datTimeContainer {
  padding: 16px;
  padding-left: 30px;
  padding-right: 82px;
}

@media (max-width: 1000px) {
  .ContactUsContainer {
    /* padding: 40px 30px 0px 30px; */
    flex-direction: column;
  }

  .contactUsSection {
    padding: 40px 30px 0px 30px;
  }

  .LeftContactContainer {
    width: 100%;
  }

  .RightContactContainer {
    width: 100%;
    margin-top: 30px;
  }

  .LastSectionOfContactUs {
    flex-direction: column;
  }

  .datTimeContainer {
    padding: 0;
  }

  .rigthLastSectionOfContactUs h2 {
    text-align: center;
  }

  .LastSectionOfContactUs img {
    width: 100vw;
  }
}

@media (max-width: 800px) {
  .ContactUsHeading {
    font-size: 30px;
    margin-bottom: 7px;
    line-height: 36px;
  }

  .MytreBeach {
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 36px;
  }

  .MytreBeach img {
    top: -2px;
  }
}

@media (max-width: 325px) {
  .MytreBeach img {
    top: 15px;
  }
}

@media (max-width: 1046px) {
  .time {
    margin-left: 0px;
  }
}

@media (max-width: 426px) {
  .dayTimeOfOpen {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .day {
    margin-left: 0;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1366px) {
  .datTimeContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
