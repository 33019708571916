#about {
  background: #273c89;
  color: #ffff;
  padding: 50px 40px 80px 40px;
}

.sectionAboutBody {
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  flex-wrap: wrap;
}

#about a {
  color: white;
}

.aboutHeader {
  position: relative;
}

.aboutHeader h2 {
  color: #ffffff;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 57px;
  font-style: inherit;
  width: 50%;
}

.aboutHeader .linksWrap {
  width: 50%;
}

.links {
  display: flex;
  margin-right: calc(-80px / 2);
  margin-left: calc(-80px / 2);
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  list-style-type: none;
}

.links a {
  margin-right: calc(80px / 2);
  margin-left: calc(80px / 2);
  position: relative;
  text-decoration: none;
  transition: transform 0.15s ease-in;
}

.links a:hover {
  transform: scale(1.05);
}


.links a img {
  position: absolute;
  right: -20px;
  top: 33px;
}

.links.blogLink{
  margin-right: 10px;
}

.valueAndTeamButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 102px;
  top: -88px;
}

.aboutUsLink {
  font-family: "Aileron", Sans-serif, serif;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 43px;
  font-size: 30px;
  padding-left: 0px;
}

.aboutUsBackgroundImageWrap {
  position: relative;
  width: 100%;
  padding-bottom: 200px;
}

.aboutUsBackgroundImage {
  max-width: 980px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.aboutUsBackgroundImage img {
  width: 100%;
}

.aboutUsWrap {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 0px 30px;
  width: 100%;
  position: relative;
}

.aboutHeaderWrap {
  max-width: 980px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.aboutUsDescriptionWrap {
  display: flex;
  /* flex-wrap: wrap; */
  /* margin-right: auto; */
  position: absolute;
  max-width: 75%;
  bottom: 170px;
}

.aboutUsDescriptionWrap2 {
  bottom: 80px;
  position: static;
  margin-top: -100px;
  width: 100%;
  display: flex;
}

.aboutUsDescription {
  padding: 50px 30px 50px 50px;
  border: 14px solid #ecc36c;
  border-bottom-width: 0px;
  border-left-width: 0px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  background-color: #273c89;
  /* flex-wrap: wrap; */
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
  height: 320px;
  margin-left: 0;
}

.aboutUsPara {
  color: #ffffff;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 25px;
}

#more {
  display: none;
}

#readMoreButton{
  transition: color var(--hover-transition);
}

#readMoreButton:hover{
  color: var(--hover-color);
}

#readMoreButton {
  background: none;
  border: 1px solid #273c89;
  color: white;
  font-family: "Aileron", Sans-serif, serif;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

#readMoreButton i {
  margin-left: 5px;
}

#aboutUsArrowUp {
  display: none;
}

#aboutLinks{
  width: auto;
  flex-direction: row;
}

/* Media Queries */
@media (max-width: 1400px) {
  .aboutUsDescription {
    margin: 0px 0px 0px -50px;
  }
}

@media (max-width: 1280px) {
  .aboutUsDescription {
    margin: 0px 0px 0px -50px;
  }

  .positionRelative-sm {
    position: relative;
  }
}

@media (max-width: 1200px) {
  .aboutUsDescriptionWrap{
    max-width: 70%;
    margin-left: 40px;
  }

  #locations-link{
    bottom: -45px;
  }
}

@media (min-width: 879px) and (max-width: 939px){
  .aboutUsPara{
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .aboutUsDescriptionWrap{
    bottom: 140px;
  }
  .links {
    margin-right: calc(-50px / 2);
    margin-left: calc(-50px / 2);
  }

  .links a {
    margin-right: calc(50px / 2);
    margin-left: calc(50px / 2);
  }

  #blogLinkImg{
    right: 0;
  }

  .aboutHeader h2 {
    width: 50%;
  }

  .aboutHeader .linksWrap {
    width: 50%;
  }

  .aboutUsDescriptionWrap2 {
    width: 100%;
  }

  .aboutUsDescription {
    margin-left: -65px;
    bottom: -47px;
  }

}

@media (max-width: 879px){
  .aboutUsPara{
    line-height: 20px;
    margin-bottom: 22px;
  }
}

@media (max-width: 768px) {
  .aboutHeader h2 {
    width: 60%;
    font-size: 30px;
  }

  .aboutHeader .linksWrap {
    width: 40%;
  }

  .aboutUsLink {
    font-size: 20px;
  }

  .links {
    flex-wrap: wrap;
  }

  .sectionAboutBody {
    max-width: 767px;
  }

  .aboutUsPara{
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .aboutUsDescription {
    border-width: 28px 23px 0px 0px;
    padding: 20px 20px 20px 20px;
    margin-right: 0;
    margin-left: -60px;
    justify-content: start;
  }

  .aboutUsBackgroundImageWrap{
    padding-bottom: 0;
  }

  .aboutUsDescriptionWrap {
    max-width: 80%;
    position: relative;
    top: -13%;
  }

  .aboutUsDescriptionWrap2 {
    margin-top: -35px;
  }

}

@media screen and (max-width: 500px) {
  #blogLink{
    margin-left: 55px;
  }
}

@media (max-width: 436px) {
  .aboutHeader h2 {
    font-size: 17px;
  }

  .aboutUsLink {
    font-size: 17px;
  }

  .aboutUsDescriptionWrap{
    top: -8%;
  }

  .aboutUsDescription {
    border-width: 28px 20px 0px 0px;
    overflow: scroll;
  }

  ::-webkit-scrollbar {
    background: #273c89;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #fff;
  }
  
}